<!-- <div class="">
    <input type="text" id="user_name" class="w-full pl-4 pr-10 py-3 leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline text-gray-600 font-medium" [placeholder]="placeholder" [(ngModel)]="value" (input)="onChange()">
    <div class="absolute right-2 px-3 py-2 text-gray-400">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </div>
</div> -->

<div class="relative">
    <input type="text" id="user_name"
        class="w-full pl-4 pr-10 py-3 leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline text-gray-600 font-medium"
        [placeholder]="placeholder" [(ngModel)]="value" (input)="onChange()">
    <div class="absolute top-1 right-0 px-3 py-2 text-gray-400" (click)="clearInput()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
    </div>
</div>
<div class="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
    <div class="flex flex-col items-center py-4">
        <!-- Avatar -->
        <app-picture classStyle="w-24 h-24" [borderClassStyle]="'p-2'" [profilePic]="profilePic"
            [downloadPicture]="false" [name]="profile?.firstName" [name2]="profile?.lastName" />

        <!-- Details -->
        <div class="mt-4 text-center">
            <!-- Name -->
            <h1 class="text-xl font-semibold text-gray-900">
                {{ profile?.firstName }} {{ profile?.lastName }}
            </h1>

            <!-- Title -->
            <p class="text-sm text-gray-600">
                {{ profile?.postTitle ? profile?.postTitle : '- -' }}
            </p>

            <!-- Social Icons -->
            <div class="flex space-x-4 mt-3 justify-center">
                <a [routerLink]="['/profile/detail', profile?.id + 'E']"
                    class="p-2  text-red-500 bg-red-100 rounded-full hover:bg-red-200 transition duration-300">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class=" w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                    </svg>

                </a>
                <a [routerLink]="['/profile/detail', profile?.id + 'R']"
                    class="p-2  text-gray-500 bg-gray-100 rounded-full hover:bg-gray-200 transition duration-300">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-4 h-4 ">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>

                </a>
            </div>
        </div>
    </div>
</div>
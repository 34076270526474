import { Attachment } from "./attachment.model";

export class File {

    id?: number;
    title?: string;
    startDate?: Date;
    endDate?: Date;
    attachment: Attachment = new Attachment();

}
<!-- <img *ngIf="profilePic" [class]="classStyle" [src]="profilePic | binaryToDataUrl" alt="" />
<img *ngIf="!profilePic" [class]="classStyle" src="https://s3.eu-west-1.amazonaws.com/fractalitetest/2023-12-20T16:48:15.197376468_default-img@2x.png"> -->


<a class="block rounded-full bg-gray-200 hover:bg-gray-300 transition duration-300" [class]="borderClassStyle">
    <div class="relative rounded-full" [class]="classStyle">
        <ng-container *ngIf="profilePic; else noAttachment">
            <img *ngIf="!isUrl && profilePic" class="inset-0 h-full w-full rounded-full"
                [src]="profilePic | binaryToDataUrl" alt="" />
            <img *ngIf="isUrl && profilePic" class="inset-0 h-full w-full rounded-full" [src]="profilePic" alt="" />
        </ng-container>
        <ng-template #noAttachment>
            <span *ngIf="name"
                class="inset-0 flex h-full w-full items-center justify-center rounded-full bg-gray-300 text-white text-lg">
                {{ name?.charAt(0) | uppercase }}{{ name2?.charAt(0) | uppercase }}
            </span>
        </ng-template>
    </div>
</a>
import { Component, Input } from '@angular/core';
import { PaginationService } from 'src/app/core/services/pagination.service';

@Component({
  selector: 'app-enumerated-pagination',
  templateUrl: './enumerated-pagination.component.html',
  styleUrls: ['./enumerated-pagination.component.scss']
})
export class EnumeratedPaginationComponent {

  @Input() next: Function = () => {};  
  @Input() previous: Function = () => {};
  @Input() goToPage: Function = (page: number) => {};

  constructor(public paginationService: PaginationService) {
  }

}

import {
  Component,
  Output,
  EventEmitter,
  Input,
  HostListener,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnChanges {
  @Output() datePickerEvent = new EventEmitter<string>();
  @Input() forcedDate?: String;
  @Input() readOnly?: Boolean = false;
  @ViewChild('datepickerInput') datepickerInput?: ElementRef;

  MONTH_NAMES = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  showDatepicker = false;
  datepickerValue: string | undefined;
  month!: number; // !: mean promis it will not be null, and it will definitely be assigned
  year!: number;
  no_of_days = [] as number[];
  blankdays = [] as number[];

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    this.initDate();
    this.getNoOfDays();
  }

  ngOnInit(): void {
    this.initDate();
    this.getNoOfDays();
  }

  initDate() {
    if (this.forcedDate) {
      this.datepickerValue = new Date(
        parseInt(this.forcedDate.split('-')[0]),
        parseInt(this.forcedDate.split('-')[1]),
        parseInt(this.forcedDate.split('-')[2]),
      ).toDateString();
      let today = new Date();
      this.month = today.getMonth();
      this.year = today.getFullYear();
    } else {
      let today = new Date();
      this.month = today.getMonth();
      this.year = today.getFullYear();
      // this.datepickerValue = new Date(this.year, this.month, today.getDate()).toDateString();
    }
  }
  toggleShowDatePicker() {
    if (!this.readOnly) this.showDatepicker = !this.showDatepicker;
  }

  isToday(date: any) {
    const today = new Date();
    const d = new Date(this.year, this.month, date);
    return today.toDateString() === d.toDateString() ? true : false;
  }

  isSelectedDate(date: number) {
    let selectedDate;
    if (this.datepickerValue) selectedDate = new Date(this.datepickerValue);
    return date === selectedDate?.getDate();
  }

  getDateValue(date: any) {
    let selectedDate = new Date(this.year, this.month, date);
    this.datepickerValue = selectedDate.toDateString();
    this.showDatepicker = false;
    this.emitEvent(selectedDate);
  }

  getNoOfDays() {
    const daysInMonth = new Date(this.year, this.month + 1, 0).getDate();

    // find where to start calendar day of week
    let dayOfWeek = new Date(this.year, this.month).getDay();
    let blankdaysArray = [];
    for (var i = 1; i <= dayOfWeek; i++) {
      blankdaysArray.push(i);
    }

    let daysArray = [];
    for (var i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }

    this.blankdays = blankdaysArray;
    this.no_of_days = daysArray;
  }

  trackByIdentity = (index: number, item: any) => item;

  emitEvent = (date?: Date) => {
    this.datePickerEvent.emit(date?.toLocaleDateString('sv'));
  };

  clearCalendar() {
    this.forcedDate = undefined;
    this.datepickerValue = '';
    this.emitEvent(undefined);
  }

  // Handle clicks outside the input
  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: Event): void {
    if (this.datepickerInput != undefined && this.showDatepicker &&
      !this.datepickerInput?.nativeElement.contains(event.target) ) {
      // Clicked outside the input, so close the datepicker
      this.showDatepicker = false;
    }
  }

}

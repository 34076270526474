import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-auto-complet-input',
  templateUrl: './auto-complet-input.component.html',
  styleUrls: ['./auto-complet-input.component.scss']
})
export class AutoCompletInputComponent {

  value: string = '';
  @Output() valueEvent = new EventEmitter<string>();
  @Input() placeholder: string = '';

  emitEvent = (value: string) => this.valueEvent.emit(value);

  onChange = () => {
    console.log(this.value);
    
    this.emitEvent(this.value);
  }

  clearInput() {
    this.value = '';
    this.emitEvent(this.value);
  }

}

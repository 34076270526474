import { Component, Input } from '@angular/core';
import { File } from 'src/app/core/models/file.model';
import { FileService } from 'src/app/core/services/file.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Attachment } from 'src/app/core/models/attachment.model';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {

  picture: any;
  @Input() attachment: Attachment | undefined;

  constructor(private fileService: FileService, private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    let type = this.attachment?.type ?? '';
    if (type.includes('image')) {
      this.getFiles();
    }
  }

  getFiles() {
    if (this.attachment?.url == undefined || this.attachment?.url == '') return;
    this.fileService.getFile(this.attachment?.url ?? '')
      .then(data => {
        // const byteArray = new Uint8Array(data);
        // const blob = new Blob([byteArray], { type: 'image/' + this.attachment?.attachmentGroup }); // Replace with the appropriate image type
        // const objectUrl = URL.createObjectURL(blob);
        // this.imageSrc = this.sanitizer.bypassSecurityTrustUrl(objectUrl);
        this.picture = data;
      }).catch(error => {
        console.log(error);
      });
  }

}

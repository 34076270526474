import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ResponseData } from 'src/app/core/dtos/pagination-response.dto';

@Component({
  selector: 'app-second-pagination',
  templateUrl: './second-pagination.component.html',
  styleUrls: ['./second-pagination.component.scss'],
})
export class SecondPaginationComponent implements  OnChanges {
  
  @Input() pagination?: ResponseData;
  @Output() onPageChange = new EventEmitter<number>();

  currentPage: number = 1;
  totalElements: number = 0;
  totalPages: number = 0;
  leftNumbers: Array<number> = [];
  rightNumbers: Array<number> = [];

 ngOnChanges(changes: SimpleChanges): void {  
    if (changes['pagination'] && changes['pagination'].currentValue) {
      const { size, totalElements, totalPages } = changes['pagination'].currentValue.data;
      this.totalElements = totalElements;
      this.totalPages = totalPages;
      this.initEnumeratedPagination(size, totalElements, totalPages);
    }
  }

  initEnumeratedPagination(currentPageSize: number, totalElements: number, totalPages: number) {
    //this.currentPage = currentPageSize;
    this.leftNumbers = [];
    this.rightNumbers = [];

    let leftPage = this.currentPage;
    let rightPage = this.currentPage;

    while (leftPage > 1 && this.leftNumbers.length < 3) {
      leftPage--;
      this.leftNumbers.unshift(leftPage);
    }

    while (rightPage < this.totalPages && this.rightNumbers.length < 3) {
      rightPage++;
      this.rightNumbers.push(rightPage);
    }
  }


  next() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.onPageChange.emit(this.currentPage);
    }
  }

  previous() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.onPageChange.emit(this.currentPage);
    }
  }

  goToPage(page: number) {
    if (page > 0 && page <= this.totalPages) {
      this.currentPage = page;
      this.onPageChange.emit(this.currentPage);
    }
  }

}

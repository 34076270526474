<li>
  <div class="flex items-center gap-x-6">
    <app-picture classStyle="h-16 w-16" [profilePic]="profilePic" [name]="profile?.firstName"
      [name2]="profile?.lastName" />
    <div>
      <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">
        {{ profile?.firstName }} {{ profile?.lastName }}</h3>
      <p class="text-sm font-semibold leading-6 text-red-500">{{ profile?.postTitle }}</p>
    </div>
  </div>
</li>
export const morrocanBorders = [
  { lat: 35.926519149000015, lng: -5.405490480999902 },
  { lat: 35.92446523600016, lng: -5.399322068999936 },
  { lat: 35.924503852782905, lng: -5.398858678935312 },
  { lat: 35.90204742499999, lng: -5.389717976999862 },
  { lat: 35.881686910000056, lng: -5.378400837999891 },
  { lat: 35.863806865000086, lng: -5.362897908999884 },
  { lat: 35.847365679033928, lng: -5.340725500097989 },
  { lat: 35.847357489000032, lng: -5.340728318999908 },
  { lat: 35.847113348000065, lng: -5.340809699999909 },
  { lat: 35.832586981000034, lng: -5.345692511999857 },
  { lat: 35.807684637000094, lng: -5.342355923999946 },
  { lat: 35.75584544499999, lng: -5.327381964999916 },
  { lat: 35.713771877000127, lng: -5.322621222999914 },
  { lat: 35.701727606000119, lng: -5.318755662999905 },
  { lat: 35.69245026200015, lng: -5.312652147999899 },
  { lat: 35.685207424000126, lng: -5.304798956999917 },
  { lat: 35.684312242000047, lng: -5.298898891999897 },
  { lat: 35.686509507000139, lng: -5.28189042899993 },
  { lat: 35.685207424000126, lng: -5.276763475999928 },
  { lat: 35.679388739000089, lng: -5.275380011999857 },
  { lat: 35.6642113300001, lng: -5.277455206999946 },
  { lat: 35.657904364000061, lng: -5.276763475999928 },
  { lat: 35.604722398000078, lng: -5.25999915299991 },
  { lat: 35.584621486000017, lng: -5.249419725999928 },
  { lat: 35.568508205000128, lng: -5.228423631999903 },
  { lat: 35.543768622000172, lng: -5.186187303999873 },
  { lat: 35.516099351000079, lng: -5.150786912999877 },
  { lat: 35.417466539000188, lng: -5.066273566999939 },
  { lat: 35.40753815300009, lng: -5.050852016999897 },
  { lat: 35.403998114000117, lng: -5.02635657499988 },
  { lat: 35.399237372000115, lng: -5.008208787999877 },
  { lat: 35.387884833000143, lng: -4.98851477799991 },
  { lat: 35.362453518000123, lng: -4.954701300999943 },
  { lat: 35.330308335000083, lng: -4.926503058999884 },
  { lat: 35.314520575000031, lng: -4.909331834999932 },
  { lat: 35.301988023000106, lng: -4.864979620999918 },
  { lat: 35.25999583500014, lng: -4.810617641999897 },
  { lat: 35.24103424700003, lng: -4.77049719999988 },
  { lat: 35.20538971600017, lng: -4.62828528599988 },
  { lat: 35.180772203000075, lng: -4.495432094999927 },
  { lat: 35.180568752000042, lng: -4.494252081999917 },
  { lat: 35.151841539000159, lng: -4.376047329999949 },
  { lat: 35.150132554000081, lng: -4.347645636999857 },
  { lat: 35.15477122599999, lng: -4.327056443999908 },
  { lat: 35.184881903000033, lng: -4.269398566999939 },
  { lat: 35.204779364000117, lng: -4.130604620999861 },
  { lat: 35.235907294000114, lng: -4.046701626999891 },
  { lat: 35.250433661000059, lng: -3.961293097999942 },
  { lat: 35.26683177300005, lng: -3.919341600999871 },
  { lat: 35.25527578300013, lng: -3.909413214999859 },
  { lat: 35.250918294000101, lng: -3.91253041899995 },
  { lat: 35.247555847000157, lng: -3.924672585999872 },
  { lat: 35.243819796000125, lng: -3.92952945299993 },
  { lat: 35.23784211400006, lng: -3.925046190999893 },
  { lat: 35.230893401000131, lng: -3.918467005999986 },
  { lat: 35.214172586000146, lng: -3.90390101899996 },
  { lat: 35.208406599000043, lng: -3.889257349999895 },
  { lat: 35.207342841000028, lng: -3.8685603509999 },
  { lat: 35.20538971600017, lng: -3.840891079999921 },
  { lat: 35.206773179000052, lng: -3.821929490999906 },
  { lat: 35.209214585, lng: -3.787464972999885 },
  { lat: 35.218451239000061, lng: -3.764800584999932 },
  { lat: 35.236395575000088, lng: -3.755523240999878 },
  { lat: 35.26365794499999, lng: -3.747141079999921 },
  { lat: 35.282294012000179, lng: -3.726226365999935 },
  { lat: 35.290838934000092, lng: -3.699574347999914 },
  { lat: 35.287909247000172, lng: -3.673573370999918 },
  { lat: 35.277004299000012, lng: -3.654204881999903 },
  { lat: 35.245347398000135, lng: -3.612212693999936 },
  { lat: 35.233303127000013, lng: -3.590972459999932 },
  { lat: 35.22353750200007, lng: -3.516021287999905 },
  { lat: 35.211004950000174, lng: -3.489328579999977 },
  { lat: 35.210679429000052, lng: -3.477406378999916 },
  { lat: 35.211818752000099, lng: -3.464751756999874 },
  { lat: 35.211615302000141, lng: -3.453846808999884 },
  { lat: 35.208644924000126, lng: -3.442860480999912 },
  { lat: 35.200832424000126, lng: -3.423247850999871 },
  { lat: 35.197943427, lng: -3.412912563999953 },
  { lat: 35.194525458, lng: -3.362049933999913 },
  { lat: 35.201808986000017, lng: -3.319243943999936 },
  { lat: 35.238226630000085, lng: -3.195139126999948 },
  { lat: 35.24909088700015, lng: -3.173939581999946 },
  { lat: 35.288560289000131, lng: -3.128651495999861 },
  { lat: 35.294134833000143, lng: -3.118031378999888 },
  { lat: 35.293646552000055, lng: -3.109730597999914 },
  { lat: 35.289536851000108, lng: -3.100412563999981 },
  { lat: 35.286281643000152, lng: -3.089670376999948 },
  { lat: 35.287909247000172, lng: -3.077056443999879 },
  { lat: 35.314439195000048, lng: -3.045399542999917 },
  { lat: 35.393540757000139, lng: -3.006255662999877 },
  { lat: 35.424505927000084, lng: -2.980824347999942 },
  { lat: 35.435003973000121, lng: -2.982818162999905 },
  { lat: 35.443304755000113, lng: -2.977935350999928 },
  { lat: 35.446437893000095, lng: -2.968576626999891 },
  { lat: 35.441229559000092, lng: -2.956654425999943 },
  { lat: 35.433050848000093, lng: -2.952219204999977 },
  { lat: 35.415838934000178, lng: -2.957590298999918 },
  { lat: 35.403998114000117, lng: -2.952951626999891 },
  { lat: 35.373480536000088, lng: -2.96304277299987 },
  { lat: 35.334947007, lng: -2.949777798999946 },
  { lat: 35.330267645000092, lng: -2.948109503999916 },
  { lat: 35.329779364, lng: -2.94782467399989 },
  { lat: 35.329768125043657, lng: -2.947817735929419 },
  { lat: 35.313866272000084, lng: -2.966920125999877 },
  { lat: 35.286219381000151, lng: -2.963767862999902 },
  { lat: 35.267874248000069, lng: -2.943045613999885 },
  { lat: 35.276915640166763, lng: -2.912906597796621 },
  { lat: 35.25999583500014, lng: -2.899037238999881 },
  { lat: 35.250962632000139, lng: -2.907948370999918 },
  { lat: 35.23578522300015, lng: -2.91234290299991 },
  { lat: 35.219061591000113, lng: -2.913319464999887 },
  { lat: 35.186712958, lng: -2.909820115999878 },
  { lat: 35.173570054000137, lng: -2.905140753999973 },
  { lat: 35.161810614000061, lng: -2.896392381999903 },
  { lat: 35.146714585000055, lng: -2.881988084999932 },
  { lat: 35.131293036000116, lng: -2.85606848899991 },
  { lat: 35.121893622000087, lng: -2.820952928999958 },
  { lat: 35.115952867000161, lng: -2.748117641999897 },
  { lat: 35.145168361000017, lng: -2.791818813999896 },
  { lat: 35.217352606000119, lng: -2.868275519999912 },
  { lat: 35.238511460000112, lng: -2.883168097999942 },
  { lat: 35.246323960000026, lng: -2.878407355999855 },
  { lat: 35.237616278000118, lng: -2.864857550999886 },
  { lat: 35.194525458, lng: -2.816395636999914 },
  { lat: 35.183783270000092, lng: -2.808664516999897 },
  { lat: 35.143255927000055, lng: -2.761097785999908 },
  { lat: 35.122992255000142, lng: -2.718332485999895 },
  { lat: 35.108221747000144, lng: -2.666737433999856 },
  { lat: 35.096136786000059, lng: -2.563221808999884 },
  { lat: 35.099351304000109, lng: -2.517486131999874 },
  { lat: 35.104396877000156, lng: -2.493316209999875 },
  { lat: 35.112616278000147, lng: -2.477202928999901 },
  { lat: 35.132473049000154, lng: -2.456125454999892 },
  { lat: 35.145086981000034, lng: -2.437123175999886 },
  { lat: 35.149155992000189, lng: -2.416737433999884 },
  { lat: 35.118841864, lng: -2.321441209999932 },
  { lat: 35.115952867000161, lng: -2.299061652999939 },
  { lat: 35.109564520000148, lng: -2.275257941999939 },
  { lat: 35.097235419000114, lng: -2.248687303999901 },
  { lat: 35.08930084800015, lng: -2.222564256999931 },
  { lat: 35.049954733000064, lng: -2.22112585499994 },
  { lat: 35.023444723000139, lng: -2.211669067999878 },
  { lat: 35.003600973000161, lng: -2.193789021999919 },
  { lat: 34.994040833000085, lng: -2.163299926999912 },
  { lat: 34.971923320000158, lng: -2.126041218999887 },
  { lat: 34.947687073000068, lng: -2.094932006999954 },
  { lat: 34.929703674000095, lng: -2.061238972999888 },
  { lat: 34.92624135400014, lng: -2.016280476999981 },
  { lat: 34.918179830000057, lng: -2.003619750999945 },
  { lat: 34.906345927000061, lng: -1.999692341999975 },
  { lat: 34.892703349000143, lng: -1.998400430999908 },
  { lat: 34.878854065000084, lng: -1.993491170999988 },
  { lat: 34.865263164000069, lng: -1.979745238999954 },
  { lat: 34.83808136, lng: -1.926725218999934 },
  { lat: 34.811674704000112, lng: -1.892825479999942 },
  { lat: 34.756690979000084, lng: -1.787715616999975 },
  { lat: 34.741343079000032, lng: -1.769525512999849 },
  { lat: 34.734056702000132, lng: -1.773142862999919 },
  { lat: 34.725840149000092, lng: -1.786113646999922 },
  { lat: 34.680726624000144, lng: -1.810504923999872 },
  { lat: 34.61359893800001, lng: -1.862956502999907 },
  { lat: 34.596649069000094, lng: -1.871121377999941 },
  { lat: 34.494174703000155, lng: -1.750663614999894 },
  { lat: 34.485053813000079, lng: -1.714231729999909 },
  { lat: 34.479679464000114, lng: -1.702966267999955 },
  { lat: 34.372450867000069, lng: -1.80962642399993 },
  { lat: 34.334701233000132, lng: -1.771334187999912 },
  { lat: 34.290311178000152, lng: -1.746064412999942 },
  { lat: 34.105955506000086, lng: -1.674750935999981 },
  { lat: 34.092020705000138, lng: -1.672085147999951 },
  { lat: 34.079212952000105, lng: -1.669634968999901 },
  { lat: 34.059188334000069, lng: -1.672115437999878 },
  { lat: 33.89808705600008, lng: -1.718727579999921 },
  { lat: 33.851164857000143, lng: -1.722086547999936 },
  { lat: 33.801994730000089, lng: -1.713043172999988 },
  { lat: 33.772823385000052, lng: -1.702501179999928 },
  { lat: 33.761816305000153, lng: -1.703224650999914 },
  { lat: 33.747062683000152, lng: -1.710821084999878 },
  { lat: 33.736365662000068, lng: -1.720949666999928 },
  { lat: 33.727864889000145, lng: -1.732576863999896 },
  { lat: 33.717762147000187, lng: -1.742240355999911 },
  { lat: 33.702388408000118, lng: -1.746787881999865 },
  { lat: 33.686601257000049, lng: -1.740741739999919 },
  { lat: 33.677712911000114, lng: -1.725393838999935 },
  { lat: 33.667325949000187, lng: -1.69071895299993 },
  { lat: 33.656499736000157, lng: -1.673252319999961 },
  { lat: 33.644691671000047, lng: -1.662400268999932 },
  { lat: 33.554438782000105, lng: -1.617338418999879 },
  { lat: 33.521469218000064, lng: -1.612739216999927 },
  { lat: 33.494235738000057, lng: -1.625399942999877 },
  { lat: 33.475528870000076, lng: -1.640386107999859 },
  { lat: 33.419770000000128, lng: -1.659144653999931 },
  { lat: 33.394603577000098, lng: -1.67283890799996 },
  { lat: 33.369230449, lng: -1.683225869999887 },
  { lat: 33.270838521000158, lng: -1.683380899999918 },
  { lat: 33.237972311000036, lng: -1.674234171999927 },
  { lat: 33.196605327, lng: -1.623591267999871 },
  { lat: 33.168028260000128, lng: -1.605607869999886 },
  { lat: 33.136608989000095, lng: -1.592068643999909 },
  { lat: 33.111985169000107, lng: -1.571398071999909 },
  { lat: 33.091831360000029, lng: -1.545508178999882 },
  { lat: 33.073977153000087, lng: -1.516362670999854 },
  { lat: 33.060205384000042, lng: -1.499516153999878 },
  { lat: 33.039483134000093, lng: -1.493056599999903 },
  { lat: 33.016151225000058, lng: -1.493366658999889 },
  { lat: 32.99429209500012, lng: -1.496518920999961 },
  { lat: 32.984008485000132, lng: -1.498844360999897 },
  { lat: 32.974629212000181, lng: -1.502978474999907 },
  { lat: 32.966309306000127, lng: -1.508869587999897 },
  { lat: 32.959488017000169, lng: -1.516362670999854 },
  { lat: 32.933649801000129, lng: -1.558789021999871 },
  { lat: 32.742395325000174, lng: -1.423345092999881 },
  { lat: 32.71877919600017, lng: -1.390530558999956 },
  { lat: 32.698909607000147, lng: -1.327020223999909 },
  { lat: 32.517008566000143, lng: -1.047502400999861 },
  { lat: 32.494400127000105, lng: -1.031999470999949 },
  { lat: 32.439442241000066, lng: -1.089980428999894 },
  { lat: 32.417944845000036, lng: -1.123156697999917 },
  { lat: 32.404948222000186, lng: -1.160260375999883 },
  { lat: 32.399858093000105, lng: -1.201704874999933 },
  { lat: 32.392623393000136, lng: -1.217982950999954 },
  { lat: 32.374614156000135, lng: -1.23415767399996 },
  { lat: 32.356914978000063, lng: -1.244131225999894 },
  { lat: 32.320844828000062, lng: -1.257515420999908 },
  { lat: 32.22944217200002, lng: -1.273423460999879 },
  { lat: 32.21751780200016, lng: -1.2754988199999 },
  { lat: 32.209068706000082, lng: -1.275188760999924 },
  { lat: 32.200852153000042, lng: -1.276635700999918 },
  { lat: 32.190180969000139, lng: -1.282991902999925 },
  { lat: 32.184858297000105, lng: -1.289193074999929 },
  { lat: 32.173722026000164, lng: -1.305677855999903 },
  { lat: 32.167443339000059, lng: -1.309553588999961 },
  { lat: 32.151165263000124, lng: -1.305161091999963 },
  { lat: 32.150906881000154, lng: -1.288986368999872 },
  { lat: 32.163515931000106, lng: -1.251831013999976 },
  { lat: 32.16372263600006, lng: -1.23271073399988 },
  { lat: 32.158348288000084, lng: -1.211833455999908 },
  { lat: 32.146049296000157, lng: -1.195607055999886 },
  { lat: 32.125223694000098, lng: -1.190594440999917 },
  { lat: 32.089670309000141, lng: -1.210334838999899 },
  { lat: 32.081660462000158, lng: -1.249557250999942 },
  { lat: 32.084709371000187, lng: -1.324953165999943 },
  { lat: 32.085071106000086, lng: -1.333428100999925 },
  { lat: 32.086052958000053, lng: -1.357405965999959 },
  { lat: 32.087551575000148, lng: -1.395388142999877 },
  { lat: 32.0895152800001, lng: -1.445617634999934 },
  { lat: 32.091944072000146, lng: -1.506337442999921 },
  { lat: 32.094734599, lng: -1.575790567999889 },
  { lat: 32.097835185000136, lng: -1.652375039999896 },
  { lat: 32.101090800000136, lng: -1.734333861999886 },
  { lat: 32.104501445000054, lng: -1.819961710999934 },
  { lat: 32.107963765000093, lng: -1.907553262999926 },
  { lat: 32.111477763000053, lng: -1.995454873999904 },
  { lat: 32.114940084000111, lng: -2.081857869999908 },
  { lat: 32.118299052000012, lng: -2.165108601999918 },
  { lat: 32.12139963900016, lng: -2.243398396999879 },
  { lat: 32.12429351800013, lng: -2.315176960999878 },
  { lat: 32.126773987000107, lng: -2.378635620999916 },
  { lat: 32.132200013, lng: -2.516146605999893 },
  { lat: 32.089670309000141, lng: -2.695567178999852 },
  { lat: 32.076286113, lng: -2.881188923999957 },
  { lat: 32.048639221000187, lng: -2.938730631999903 },
  { lat: 31.892430122662986, lng: -2.869768686121859 },
  { lat: 31.794586472320717, lng: -2.827835693118061 },
  { lat: 31.773619975819017, lng: -3.002556497300333 },
  { lat: 31.71770931848063, lng: -3.219210294486487 },
  { lat: 31.672744853000054, lng: -3.511563883999941 },
  { lat: 31.669954326, lng: -3.548745076999865 },
  { lat: 31.678274232000049, lng: -3.59137813299995 },
  { lat: 31.647820996807738, lng: -3.659506721025934 },
  { lat: 31.389234206617871, lng: -3.673484385360553 },
  { lat: 31.385165507000025, lng: -3.747466796999959 },
  { lat: 31.350645651000136, lng: -3.8025021979999 },
  { lat: 31.33715810200006, lng: -3.815188761999934 },
  { lat: 31.31886464500009, lng: -3.81921952299993 },
  { lat: 31.243365377000188, lng: -3.812914998999929 },
  { lat: 31.220524394000094, lng: -3.814982054999888 },
  { lat: 31.189776917000032, lng: -3.836169392999864 },
  { lat: 31.170191549000108, lng: -3.842835652999952 },
  { lat: 31.152828268000079, lng: -3.839321654999907 },
  { lat: 31.14383656800014, lng: -3.827461913999883 },
  { lat: 31.142906393000189, lng: -3.810796263999862 },
  { lat: 31.149676006000121, lng: -3.792967895999908 },
  { lat: 31.173447164, lng: -3.763719034999951 },
  { lat: 31.180216776000023, lng: -3.748862059999937 },
  { lat: 31.176341045000171, lng: -3.731421264999881 },
  { lat: 31.163266907000107, lng: -3.717106892999908 },
  { lat: 31.1255947880001, lng: -3.689718383999946 },
  { lat: 31.110867005000088, lng: -3.671941690999915 },
  { lat: 31.09567413300006, lng: -3.635871541999933 },
  { lat: 31.086527405000155, lng: -3.624140991999951 },
  { lat: 31.068027242000042, lng: -3.614529174999916 },
  { lat: 31.050302226000113, lng: -3.61008500199992 },
  { lat: 31.03087188800005, lng: -3.60874141499994 },
  { lat: 30.955926612245619, lng: -3.554674238516554 },
  { lat: 30.879049458405447, lng: -3.610584895854856 },
  { lat: 30.837116465401735, lng: -3.659506721025934 },
  { lat: 30.774216975896039, lng: -3.652517888858654 },
  { lat: 30.711317486390456, lng: -3.645529056691345 },
  { lat: 30.627451500382861, lng: -3.834227525208235 },
  { lat: 30.592507339546458, lng: -4.001959497223311 },
  { lat: 30.585518507379177, lng: -4.155713804903684 },
  { lat: 30.557563178709884, lng: -4.274523951747767 },
  { lat: 30.508641353538977, lng: -4.372367602089838 },
  { lat: 30.382842374527698, lng: -4.484188916766527 },
  { lat: 30.284998724185598, lng: -4.62396556011231 },
  { lat: 30.229088066847126, lng: -4.770731035625488 },
  { lat: 30.180166241676133, lng: -4.875563518134896 },
  { lat: 30.124255584337746, lng: -4.959429504142378 },
  { lat: 30.04038959833035, lng: -5.071250818819067 },
  { lat: 29.977490108824568, lng: -5.176083301328532 },
  { lat: 29.886635290649863, lng: -5.273926951670632 },
  { lat: 29.767825143805894, lng: -5.343815273343523 },
  { lat: 29.642026164794586, lng: -5.434670091518314 },
  { lat: 29.523216017950617, lng: -5.539502574027694 },
  { lat: 29.495260689281409, lng: -5.637346224369793 },
  { lat: 29.523216017950617, lng: -5.72121221037736 },
  { lat: 29.614070836125407, lng: -5.756156371213876 },
  { lat: 29.600093171790817, lng: -5.881955350225155 },
  { lat: 29.579126675289004, lng: -6.00076549706904 },
  { lat: 29.579126675289004, lng: -6.126564476080318 },
  { lat: 29.579126675289004, lng: -6.273329951593496 },
  { lat: 29.565149010954414, lng: -6.413106594939393 },
  { lat: 29.530204850117812, lng: -6.55987207045257 },
  { lat: 29.516227185783308, lng: -6.699648713798354 },
  { lat: 29.446338864110331, lng: -6.78351469980592 },
  { lat: 29.509238353615942, lng: -6.95823550398822 },
  { lat: 29.516227185783308, lng: -7.070056818664909 },
  { lat: 29.509238353615942, lng: -7.146933972505082 },
  { lat: 29.467305360612229, lng: -7.258755287181771 },
  { lat: 29.383439374604635, lng: -7.349610105356646 },
  { lat: 29.389137472000172, lng: -7.463286498999878 },
  { lat: 29.382445374000056, lng: -7.48406042499991 },
  { lat: 29.380223287000049, lng: -7.506126260999906 },
  { lat: 29.38094675700016, lng: -7.528502156999878 },
  { lat: 29.387613017000078, lng: -7.572685506999989 },
  { lat: 29.389421692000056, lng: -7.619452677999902 },
  { lat: 29.37619252500015, lng: -7.653610798999893 },
  { lat: 29.321828918000094, lng: -7.714666503999865 },
  { lat: 29.311157736, lng: -7.729988565999918 },
  { lat: 29.289324443000154, lng: -7.777995970999882 },
  { lat: 29.2390432750001, lng: -7.839129191999888 },
  { lat: 29.176230570000129, lng: -7.945014200999935 },
  { lat: 29.099852804000179, lng: -8.036326456999859 },
  { lat: 29.07933726, lng: -8.069657754999923 },
  { lat: 29.035541484000092, lng: -8.182312377999921 },
  { lat: 28.994768779000125, lng: -8.250473591999935 },
  { lat: 28.9390615840001, lng: -8.316774454999859 },
  { lat: 28.930379944000137, lng: -8.333259235999947 },
  { lat: 28.916530661000152, lng: -8.368450886999909 },
  { lat: 28.905781963000052, lng: -8.383488728999879 },
  { lat: 28.852348531000032, lng: -8.4178018799999 },
  { lat: 28.841005555000052, lng: -8.430410929999937 },
  { lat: 28.818758851000027, lng: -8.475834512999882 },
  { lat: 28.787081198000109, lng: -8.520818847999891 },
  { lat: 28.725947978000178, lng: -8.648847208999882 },
  { lat: 28.711685283000023, lng: -8.667605753999879 },
  { lat: 26.00001617500007, lng: -8.687656208999925 },
  { lat: 25.995003560000015, lng: -8.707215738999935 },
  { lat: 25.995003560000015, lng: -8.792042602999914 },
  { lat: 25.994951884000102, lng: -8.892863321999897 },
  { lat: 25.994951884000102, lng: -8.993580688999941 },
  { lat: 25.994951884000102, lng: -9.094246378999912 },
  { lat: 25.994951884000102, lng: -9.195015421999898 },
  { lat: 25.994951884000102, lng: -9.295732787999953 },
  { lat: 25.994951884000102, lng: -9.396450154999911 },
  { lat: 25.994951884000102, lng: -9.497167520999881 },
  { lat: 25.994951884000102, lng: -9.597884887999953 },
  { lat: 25.994951884000102, lng: -9.698653930999939 },
  { lat: 25.994951884000102, lng: -9.799371297999898 },
  { lat: 25.994951884000102, lng: -9.900036986999851 },
  { lat: 25.994951884000102, lng: -10.000806030999939 },
  { lat: 25.994951884000102, lng: -10.101523396999909 },
  { lat: 25.994951884000102, lng: -10.202292439999979 },
  { lat: 25.994951884000102, lng: -10.302958129999922 },
  { lat: 25.994951884000102, lng: -10.403675496999881 },
  { lat: 25.994951884000102, lng: -10.504444539999952 },
  { lat: 25.994951884000102, lng: -10.605161905999921 },
  { lat: 25.994951884000102, lng: -10.705879272999908 },
  { lat: 25.994951884000102, lng: -10.806596639999952 },
  { lat: 25.994951884000102, lng: -10.907314005999922 },
  { lat: 25.994951884000102, lng: -11.008083047999918 },
  { lat: 25.994951884000102, lng: -11.108748738999878 },
  { lat: 25.994951884000102, lng: -11.209517781999949 },
  { lat: 25.994951884000102, lng: -11.310235147999919 },
  { lat: 25.994951884000102, lng: -11.410952514999877 },
  { lat: 25.994951884000102, lng: -11.511721557999863 },
  { lat: 25.994951884000102, lng: -11.612438923999918 },
  { lat: 25.994951884000102, lng: -11.713207966999903 },
  { lat: 25.994951884000102, lng: -11.813821980999933 },
  { lat: 25.994951884000102, lng: -11.914591023999918 },
  { lat: 25.994900208000075, lng: -12.015308389999888 },
  { lat: 25.919245910000114, lng: -12.015308389999888 },
  { lat: 25.84369496700009, lng: -12.015308389999888 },
  { lat: 25.768092347000049, lng: -12.015308389999888 },
  { lat: 25.692489726000105, lng: -12.015308389999888 },
  { lat: 25.616938782000091, lng: -12.015308389999888 },
  { lat: 25.54133616200005, lng: -12.015308389999888 },
  { lat: 25.465733541000105, lng: -12.015308389999888 },
  { lat: 25.390182597000091, lng: -12.015308389999888 },
  { lat: 25.314579977000051, lng: -12.015308389999888 },
  { lat: 25.238925680000079, lng: -12.015308389999888 },
  { lat: 25.163323060000039, lng: -12.015308389999888 },
  { lat: 25.087797954000024, lng: -12.015308389999888 },
  { lat: 25.012247009000077, lng: -12.015308389999888 },
  { lat: 24.936618551000038, lng: -12.015308389999888 },
  { lat: 24.861015931000111, lng: -12.015308389999888 },
  { lat: 24.785464986000079, lng: -12.015308389999888 },
  { lat: 24.709862366000038, lng: -12.015308389999888 },
  { lat: 24.634259746000083, lng: -12.015308389999888 },
  { lat: 24.558631287000139, lng: -12.015308389999888 },
  { lat: 24.483080343000012, lng: -12.015308389999888 },
  { lat: 24.407477722000067, lng: -12.015308389999888 },
  { lat: 24.331900941000043, lng: -12.015308389999888 },
  { lat: 24.256298319000081, lng: -12.015308389999888 },
  { lat: 24.180695699000069, lng: -12.015308389999888 },
  { lat: 24.105093079000113, lng: -12.015308389999888 },
  { lat: 24.029542135000071, lng: -12.015308389999888 },
  { lat: 23.953939514000041, lng: -12.015308389999888 },
  { lat: 23.878311056000086, lng: -12.015308389999888 },
  { lat: 23.802734274000073, lng: -12.015308389999888 },
  { lat: 23.727105815000101, lng: -12.015308389999888 },
  { lat: 23.651477356000072, lng: -12.015308389999888 },
  { lat: 23.57592641200003, lng: -12.015308389999888 },
  { lat: 23.495181986000091, lng: -12.015308389999888 },
  { lat: 23.460998027000102, lng: -12.019339151999873 },
  { lat: 23.444978333000051, lng: -12.032723347999905 },
  { lat: 23.41934682200008, lng: -12.1345775959999 },
  { lat: 23.322479350000066, lng: -12.353892374999873 },
  { lat: 23.312531637000063, lng: -12.389910847999971 },
  { lat: 23.290284933000123, lng: -12.558376017999933 },
  { lat: 23.270828756000043, lng: -12.619431721999916 },
  { lat: 23.230753683000046, lng: -12.68221858699988 },
  { lat: 23.161868998000045, lng: -12.789938109999866 },
  { lat: 23.082054749000037, lng: -12.915098428999954 },
  { lat: 23.018001811000147, lng: -13.015247354999872 },
  { lat: 22.995212504000051, lng: -13.034341796999854 },
  { lat: 22.995160828000053, lng: -13.034341796999854 },
  { lat: 22.883539734000095, lng: -13.119892130999915 },
  { lat: 22.820003560000146, lng: -13.152293253999915 },
  { lat: 22.752695008000046, lng: -13.165496581999889 },
  { lat: 22.688797099000084, lng: -13.154980427999931 },
  { lat: 22.56022613500005, lng: -13.106352904999937 },
  { lat: 22.495475566000067, lng: -13.093330444999879 },
  { lat: 22.245439148500083, lng: -13.076832743499864 },
  { lat: 21.995402731000098, lng: -13.060335041999934 },
  { lat: 21.581939241000015, lng: -13.032092883999951 },
  { lat: 21.495329896000015, lng: -13.026176920999944 },
  { lat: 21.333427633000042, lng: -13.015247354999872 },
  { lat: 21.333427633000042, lng: -13.161724202999977 },
  { lat: 21.333375956000026, lng: -13.203091186999899 },
  { lat: 21.333375956000026, lng: -13.31985408599985 },
  { lat: 21.333375956000026, lng: -13.501419229999954 },
  { lat: 21.333324280000113, lng: -13.736908731999961 },
  { lat: 21.333272603000097, lng: -14.015496378999956 },
  { lat: 21.333226059000054, lng: -14.295597564999923 },
  { lat: 21.33322092700007, lng: -14.32648514899995 },
  { lat: 21.333169251000072, lng: -14.658971312999881 },
  { lat: 21.333169251000072, lng: -15.002257853999964 },
  { lat: 21.333065898000115, lng: -15.345544392999926 },
  { lat: 21.333065898000115, lng: -15.678082234999891 },
  { lat: 21.333014222000116, lng: -15.98901932799987 },
  { lat: 21.332910868000084, lng: -16.26771032699989 },
  { lat: 21.332910868000084, lng: -16.503148152999955 },
  { lat: 21.332910868000084, lng: -16.684635782999862 },
  { lat: 21.332859192000086, lng: -16.801424519999898 },
  { lat: 21.332859192000086, lng: -16.84281734299995 },
  { lat: 21.332859192000086, lng: -16.958830932999973 },
  { lat: 21.324590963000048, lng: -16.968339395999863 },
  { lat: 21.23782623300005, lng: -16.985702677999967 },
  { lat: 21.141914775000089, lng: -17.004926309999888 },
  { lat: 21.027399801000087, lng: -17.047378499999922 },
  { lat: 20.995463766000114, lng: -17.054018920999937 },
  { lat: 20.904513245000089, lng: -17.077428344999902 },
  { lat: 20.874747620000107, lng: -17.0811748859999 },
  { lat: 20.766913153000132, lng: -17.056874152499887 },
  { lat: 20.792466539000117, lng: -17.068348761999857 },
  { lat: 20.837591864000075, lng: -17.100209113999881 },
  { lat: 20.862494208000015, lng: -17.104644334999875 },
  { lat: 20.876166083000044, lng: -17.101389126999919 },
  { lat: 20.89988841400006, lng: -17.092274542999917 },
  { lat: 20.914007880000099, lng: -17.090402798999946 },
  { lat: 20.961818752000028, lng: -17.090402798999946 },
  { lat: 20.979315497000073, lng: -17.086293097999913 },
  { lat: 21.030910549000026, lng: -17.067860480999883 },
  { lat: 21.328924872000073, lng: -17.0267634759999 },
  { lat: 21.365057684000107, lng: -17.017445441999939 },
  { lat: 21.379828192000076, lng: -17.015248175999943 },
  { lat: 21.41997109758168, lng: -17.013743325345189 },
  { lat: 21.419971097581723, lng: -17.013743325345189 },
  { lat: 21.419989325000145, lng: -17.013742641999897 },
  { lat: 21.44521719, lng: -17.012806769999912 },
  { lat: 21.592596747000087, lng: -16.971058722999885 },
  { lat: 21.636542059000035, lng: -16.966908331999946 },
  { lat: 21.67446523600016, lng: -16.970326300999943 },
  { lat: 21.697251695000162, lng: -16.968006964999887 },
  { lat: 21.724758205000015, lng: -16.951079881999902 },
  { lat: 21.736029364, lng: -16.947661912999905 },
  { lat: 21.748277085000083, lng: -16.946400519999884 },
  { lat: 21.759995835000055, lng: -16.947010870999861 },
  { lat: 21.765366929000109, lng: -16.950266079999892 },
  { lat: 21.775620835000055, lng: -16.963246222999885 },
  { lat: 21.781154690000065, lng: -16.966908331999946 },
  { lat: 21.79580312700007, lng: -16.961537238999881 },
  { lat: 21.825669664000159, lng: -16.956939256999931 },
  { lat: 21.866603908000101, lng: -16.93781490799995 },
  { lat: 21.907945054000109, lng: -16.929798956999917 },
  { lat: 21.931301174000012, lng: -16.921009894999941 },
  { lat: 22.130560614, lng: -16.81663977799991 },
  { lat: 22.165716864000061, lng: -16.780669725999928 },
  { lat: 22.174953518000123, lng: -16.775054490999935 },
  { lat: 22.180975653000033, lng: -16.773426886999914 },
  { lat: 22.205023505000085, lng: -16.762074347999942 },
  { lat: 22.21019114799999, lng: -16.75633704299986 },
  { lat: 22.221136786000145, lng: -16.739898240999878 },
  { lat: 22.226752020000148, lng: -16.734120245999918 },
  { lat: 22.260972398000106, lng: -16.717884894999884 },
  { lat: 22.275458075000174, lng: -16.706776495999947 },
  { lat: 22.28408437700007, lng: -16.682606574999852 },
  { lat: 22.289536851000108, lng: -16.6771541009999 },
  { lat: 22.294419664000159, lng: -16.670318162999877 },
  { lat: 22.295070705000128, lng: -16.659006313999896 },
  { lat: 22.291693427000112, lng: -16.650257941999911 },
  { lat: 22.285956122000059, lng: -16.642648891999983 },
  { lat: 22.273911851000136, lng: -16.631703253999916 },
  { lat: 22.314886786000145, lng: -16.521839972999942 },
  { lat: 22.329901434000149, lng: -16.502186652999882 },
  { lat: 22.352280992000189, lng: -16.482167120999918 },
  { lat: 22.377386786000088, lng: -16.466623501999919 },
  { lat: 22.493068752000013, lng: -16.431507941999939 },
  { lat: 22.510443427, lng: -16.429351365999935 },
  { lat: 22.521795966000141, lng: -16.419545050999915 },
  { lat: 22.568264065, lng: -16.36978105399993 },
  { lat: 22.583075262000179, lng: -16.357289191999911 },
  { lat: 22.64695872599999, lng: -16.344390428999901 },
  { lat: 22.732001044000029, lng: -16.341379360999895 },
  { lat: 22.753729559000092, lng: -16.336822068999936 },
  { lat: 22.789496161000031, lng: -16.32485917899993 },
  { lat: 22.806057033000073, lng: -16.316477016999954 },
  { lat: 22.832098700000145, lng: -16.296457485999895 },
  { lat: 22.842189846000011, lng: -16.296254035999937 },
  { lat: 22.850165106000148, lng: -16.299956834999875 },
  { lat: 22.856756903000061, lng: -16.30211341099988 },
  { lat: 22.868312893000066, lng: -16.302154100999871 },
  { lat: 22.875067450000117, lng: -16.300607876999919 },
  { lat: 22.893459377000156, lng: -16.291737433999884 },
  { lat: 22.899807033000073, lng: -16.289621548999889 },
  { lat: 22.90338776200015, lng: -16.284779425999886 },
  { lat: 22.905585028000061, lng: -16.262603318999936 },
  { lat: 22.911566473000065, lng: -16.238636847999885 },
  { lat: 22.937974351000051, lng: -16.206450975999928 },
  { lat: 22.97345612200003, lng: -16.172963019999884 },
  { lat: 22.99323151200015, lng: -16.163238084999932 },
  { lat: 23.013495184000149, lng: -16.156483527999882 },
  { lat: 23.03522370000006, lng: -16.152577277999882 },
  { lat: 23.059068101000108, lng: -16.151275193999908 },
  { lat: 23.082464911000031, lng: -16.15953528599988 },
  { lat: 23.086127020000063, lng: -16.17906653599988 },
  { lat: 23.075873114000117, lng: -16.220204230999855 },
  { lat: 23.094427802000141, lng: -16.213124152999882 },
  { lat: 23.126166083, lng: -16.196034308999856 },
  { lat: 23.13792552299999, lng: -16.186675584999904 },
  { lat: 23.178534247000172, lng: -16.139271613999881 },
  { lat: 23.185126044000143, lng: -16.135121222999942 },
  { lat: 23.344549872000144, lng: -16.060414191999911 },
  { lat: 23.358099677000112, lng: -16.049143032999922 },
  { lat: 23.363918361000074, lng: -16.038319464999944 },
  { lat: 23.374945380000113, lng: -16.025542772999898 },
  { lat: 23.445786851000136, lng: -15.981190558999856 },
  { lat: 23.51292552299999, lng: -15.967193162999905 },
  { lat: 23.531724351000051, lng: -15.956450975999871 },
  { lat: 23.551336981000091, lng: -15.941314256999874 },
  { lat: 23.610296942000062, lng: -15.905506964999887 },
  { lat: 23.661810614000117, lng: -15.857085740999878 },
  { lat: 23.667914130000113, lng: -15.853871222999913 },
  { lat: 23.679348049000069, lng: -15.837391730999855 },
  { lat: 23.685980536000145, lng: -15.83039303299995 },
  { lat: 23.696722723000065, lng: -15.825062628999888 },
  { lat: 23.717230536000031, lng: -15.820057745999861 },
  { lat: 23.727606512000094, lng: -15.816070115999878 },
  { lat: 23.750637111000074, lng: -15.800648566999939 },
  { lat: 23.768988348000121, lng: -15.784820115999906 },
  { lat: 23.787827867000161, lng: -15.772613084999932 },
  { lat: 23.812648830000128, lng: -15.767689581999946 },
  { lat: 23.856756903000033, lng: -15.765451626999891 },
  { lat: 23.875677802000141, lng: -15.769154425999915 },
  { lat: 23.898260809000121, lng: -15.781971808999856 },
  { lat: 23.903713283000158, lng: -15.778675910999908 },
  { lat: 23.911932684000149, lng: -15.775135870999861 },
  { lat: 23.903876044000029, lng: -15.786773240999935 },
  { lat: 23.892157294000029, lng: -15.787953253999888 },
  { lat: 23.878363348000121, lng: -15.786000128999916 },
  { lat: 23.864162502000013, lng: -15.788197394999912 },
  { lat: 23.856634833000058, lng: -15.795318162999877 },
  { lat: 23.840277411000116, lng: -15.820871548999946 },
  { lat: 23.83624909100017, lng: -15.83039303299995 },
  { lat: 23.835882880000057, lng: -15.843576626999891 },
  { lat: 23.83812083500014, lng: -15.852202928999901 },
  { lat: 23.83616771, lng: -15.861724412999905 },
  { lat: 23.823187567, lng: -15.877552863999938 },
  { lat: 23.810736395000063, lng: -15.888172980999883 },
  { lat: 23.798773505000142, lng: -15.895334438999924 },
  { lat: 23.765326239000089, lng: -15.910389777999853 },
  { lat: 23.743597723, lng: -15.923329230999855 },
  { lat: 23.73725006700009, lng: -15.925933397999927 },
  { lat: 23.725165106000176, lng: -15.928089972999942 },
  { lat: 23.7052269550001, lng: -15.937489386999886 },
  { lat: 23.696234442000062, lng: -15.93960527299987 },
  { lat: 23.684312242000132, lng: -15.94823157499988 },
  { lat: 23.645005601000108, lng: -15.994862433999884 },
  { lat: 23.655422268, lng: -16.009755011999857 },
  { lat: 23.672105210000055, lng: -16.003163214999887 },
  { lat: 23.696234442000062, lng: -15.981190558999856 },
  { lat: 23.710760809000092, lng: -15.975819464999887 },
  { lat: 23.774807033000073, lng: -15.93960527299987 },
  { lat: 23.853583075000145, lng: -15.877552863999938 },
  { lat: 23.869208075000031, lng: -15.871245897999898 },
  { lat: 23.88694896000014, lng: -15.856800910999937 },
  { lat: 23.902655341000084, lng: -15.840931769999912 },
  { lat: 23.959865627000156, lng: -15.768137173999946 },
  { lat: 23.971177476000051, lng: -15.735747850999871 },
  { lat: 23.995794989000146, lng: -15.692616339999859 },
  { lat: 24.007513739000117, lng: -15.679554816999968 },
  { lat: 24.01040273600016, lng: -15.64663652299987 },
  { lat: 24.026271877000099, lng: -15.620106574999909 },
  { lat: 24.048325914000188, lng: -15.599232550999943 },
  { lat: 24.070257880000113, lng: -15.583322719999927 },
  { lat: 24.114243882000139, lng: -15.542347785999908 },
  { lat: 24.152167059000149, lng: -15.514393683999884 },
  { lat: 24.177801825000031, lng: -15.476063605999855 },
  { lat: 24.191880601000079, lng: -15.459706183999856 },
  { lat: 24.210191148000135, lng: -15.453033006999874 },
  { lat: 24.221869208000115, lng: -15.444691535999908 },
  { lat: 24.291083075000117, lng: -15.353382941999939 },
  { lat: 24.345160223, lng: -15.2982478509999 },
  { lat: 24.440741278000175, lng: -15.229562954999892 },
  { lat: 24.467474677, lng: -15.203968878999916 },
  { lat: 24.494126695000105, lng: -15.171864386999857 },
  { lat: 24.505601304000052, lng: -15.153553839999887 },
  { lat: 24.514634507000082, lng: -15.133412238999938 },
  { lat: 24.520575262000094, lng: -15.112700975999928 },
  { lat: 24.527289130000142, lng: -15.069406704999949 },
  { lat: 24.538275458, lng: -15.048329230999855 },
  { lat: 24.562445380000028, lng: -15.014800584999904 },
  { lat: 24.585679429000137, lng: -15.015370245999861 },
  { lat: 24.591538804000081, lng: -15.012237107999908 },
  { lat: 24.614488023000078, lng: -14.999989386999914 },
  { lat: 24.64142487200003, lng: -14.976226365999935 },
  { lat: 24.659247137000094, lng: -14.952137824999909 },
  { lat: 24.67177969, lng: -14.926991339999915 },
  { lat: 24.685492255000113, lng: -14.907826300999943 },
  { lat: 24.705715236000131, lng: -14.895619269999884 },
  { lat: 24.770738023000106, lng: -14.885853644999941 },
  { lat: 24.830145575000145, lng: -14.861927863999881 },
  { lat: 24.876613674000012, lng: -14.853423631999931 },
  { lat: 24.90534088700015, lng: -14.839670376999919 },
  { lat: 24.918687242000161, lng: -14.836048956999974 },
  { lat: 24.935126044000143, lng: -14.837961391999926 },
  { lat: 24.95026276200015, lng: -14.842030402999882 },
  { lat: 24.965277411000088, lng: -14.843169725999928 },
  { lat: 24.981390692000062, lng: -14.836048956999974 },
  { lat: 24.987616278000118, lng: -14.843495245999861 },
  { lat: 25.001695054000052, lng: -14.837228969999899 },
  { lat: 25.017564195000162, lng: -14.832590298999975 },
  { lat: 25.034247137000122, lng: -14.829741990999935 },
  { lat: 25.050238348000121, lng: -14.829213019999941 },
  { lat: 25.065741278000033, lng: -14.831898566999939 },
  { lat: 25.087551174000097, lng: -14.841420050999915 },
  { lat: 25.10053131700009, lng: -14.843495245999861 },
  { lat: 25.189032294000086, lng: -14.842437303999901 },
  { lat: 25.214178778000175, lng: -14.849720831999917 },
  { lat: 25.330226955000043, lng: -14.819243943999965 },
  { lat: 25.346096096000153, lng: -14.812123175999915 },
  { lat: 25.446926174000012, lng: -14.782053188999953 },
  { lat: 25.505519924000154, lng: -14.736195441999882 },
  { lat: 25.515814520000063, lng: -14.723133917999888 },
  { lat: 25.525580145000035, lng: -14.714914516999897 },
  { lat: 25.590887762000094, lng: -14.685170050999886 },
  { lat: 25.635565497000144, lng: -14.678334113999881 },
  { lat: 25.64191315300009, lng: -14.67625891799986 },
  { lat: 25.659735419000171, lng: -14.666859503999916 },
  { lat: 25.691310940000065, lng: -14.661366339999887 },
  { lat: 25.783596096000011, lng: -14.620961066999939 },
  { lat: 25.87921784100017, lng: -14.546457485999895 },
  { lat: 25.923773505000057, lng: -14.519520636999914 },
  { lat: 25.936957098, lng: -14.51390540299991 },
  { lat: 25.956854559000092, lng: -14.511057094999984 },
  { lat: 26.001613674000154, lng: -14.494984503999888 },
  { lat: 26.015448309000064, lng: -14.486561652999853 },
  { lat: 26.03148021000014, lng: -14.490345831999889 },
  { lat: 26.087795315, lng: -14.480376756999874 },
  { lat: 26.102484442, lng: -14.483306443999908 },
  { lat: 26.13271719, lng: -14.492827928999901 },
  { lat: 26.1427269550001, lng: -14.490061001999948 },
  { lat: 26.153876044000114, lng: -14.482289191999939 },
  { lat: 26.183050848000065, lng: -14.471791144999884 },
  { lat: 26.19424062700007, lng: -14.466135219999956 },
  { lat: 26.260077216000028, lng: -14.410145636999943 },
  { lat: 26.266302802, lng: -14.400746222999913 },
  { lat: 26.275946356000148, lng: -14.353179490999906 },
  { lat: 26.302232164000159, lng: -14.298939581999974 },
  { lat: 26.375474351000136, lng: -14.215931769999884 },
  { lat: 26.392238674000154, lng: -14.202056443999879 },
  { lat: 26.409491278000147, lng: -14.192779100999928 },
  { lat: 26.424383856000119, lng: -14.180246548999946 },
  { lat: 26.43378327000012, lng: -14.157053188999896 },
  { lat: 26.444403387000179, lng: -14.046864386999914 },
  { lat: 26.452378648000135, lng: -14.027740037999934 },
  { lat: 26.500881252000013, lng: -13.923736131999902 },
  { lat: 26.61810944200009, lng: -13.742014126999948 },
  { lat: 26.688910223, lng: -13.619984503999888 },
  { lat: 26.74909088700015, lng: -13.561105923999918 },
  { lat: 26.858303127000099, lng: -13.500884568999965 },
  { lat: 26.911688544000029, lng: -13.480336066999911 },
  { lat: 26.992743231000176, lng: -13.461048956999889 },
  { lat: 27.050767320000048, lng: -13.431996222999913 },
  { lat: 27.177191473, lng: -13.402455206999946 },
  { lat: 27.215277411000088, lng: -13.383656378999888 },
  { lat: 27.324448960000112, lng: -13.301096157999893 },
  { lat: 27.330023505000113, lng: -13.300770636999886 },
  { lat: 27.339341539000159, lng: -13.307769334999932 },
  { lat: 27.344956773000078, lng: -13.307932094999899 },
  { lat: 27.35175202, lng: -13.294260219999956 },
  { lat: 27.465399481000091, lng: -13.240834113999938 },
  { lat: 27.567450262000179, lng: -13.207590298999918 },
  { lat: 27.588568427, lng: -13.205433722999942 },
  { lat: 27.606512762000037, lng: -13.200795050999915 },
  { lat: 27.64484284100017, lng: -13.180978969999899 },
  { lat: 27.662490981041415, lng: -13.178254487688861 },
  { lat: 27.662502346000096, lng: -13.178252732999908 },
  { lat: 27.662787177000112, lng: -13.178212042999917 },
  { lat: 27.667141018, lng: -13.177561001999891 },
  { lat: 27.685003973000065, lng: -13.171254035999937 },
  { lat: 27.694647528000061, lng: -13.160511847999913 },
  { lat: 27.708075262000037, lng: -13.130360480999911 },
  { lat: 27.732245184000035, lng: -13.097971157999893 },
  { lat: 27.742905992000161, lng: -13.061431443999965 },
  { lat: 27.759833075000145, lng: -13.04515540299991 },
  { lat: 27.821030992000189, lng: -13.003000454999892 },
  { lat: 27.893703518000066, lng: -12.979562954999892 },
  { lat: 27.914618231000034, lng: -12.968088344999956 },
  { lat: 27.928615627000099, lng: -12.952381964999887 },
  { lat: 27.938706773000078, lng: -12.933094855999855 },
  { lat: 27.970404364000117, lng: -12.838937954999949 },
  { lat: 27.998032945000134, lng: -12.657826300999943 },
  { lat: 27.995428778000033, lng: -12.513539191999939 },
  { lat: 28.047267971000124, lng: -12.335031704999949 },
  { lat: 28.089016018000066, lng: -12.061431443999879 },
  { lat: 28.096014716000141, lng: -12.050404425999943 },
  { lat: 28.116888739000117, lng: -12.027902798999946 },
  { lat: 28.119574286, lng: -12.023101365999935 },
  { lat: 28.123195705000072, lng: -12.00560462099989 },
  { lat: 28.210109768000152, lng: -11.782378709999875 },
  { lat: 28.325628973000121, lng: -11.485585089999859 },
  { lat: 28.34845612200003, lng: -11.452788865999935 },
  { lat: 28.37592194200009, lng: -11.428822394999884 },
  { lat: 28.434881903000033, lng: -11.38931230399993 },
  { lat: 28.449774481000091, lng: -11.37401282499988 },
  { lat: 28.463853257000139, lng: -11.35594641799986 },
  { lat: 28.479681708000058, lng: -11.340972459999904 },
  { lat: 28.499741929000109, lng: -11.334706183999856 },
  { lat: 28.515204169000143, lng: -11.32591712099989 },
  { lat: 28.55158112200003, lng: -11.266468878999945 },
  { lat: 28.602484442000147, lng: -11.219634568999908 },
  { lat: 28.616766669000143, lng: -11.211822068999908 },
  { lat: 28.639715887000037, lng: -11.170318162999905 },
  { lat: 28.647406317000147, lng: -11.162464972999913 },
  { lat: 28.66107819200009, lng: -11.154204881999931 },
  { lat: 28.667669989000061, lng: -11.149159308999913 },
  { lat: 28.691839911000059, lng: -11.114979620999918 },
  { lat: 28.695746161000059, lng: -11.11156165299991 },
  { lat: 28.726304429, lng: -11.091053839999859 },
  { lat: 28.752386786000059, lng: -11.06309973899991 },
  { lat: 28.76170482000019, lng: -11.047596808999913 },
  { lat: 28.890204169000143, lng: -10.762603318999879 },
  { lat: 28.930568752000042, lng: -10.694203253999888 },
  { lat: 28.967718817000119, lng: -10.612416144999912 },
  { lat: 28.990423895000063, lng: -10.573801235999952 },
  { lat: 29.092189846000039, lng: -10.45091712099989 },
  { lat: 29.22939687700007, lng: -10.347889777999853 },
  { lat: 29.257961330000157, lng: -10.320871548999889 },
  { lat: 29.271877346000124, lng: -10.301991339999859 },
  { lat: 29.284125067, lng: -10.264027472999942 },
  { lat: 29.299383856000148, lng: -10.248402472999942 },
  { lat: 29.33307526200015, lng: -10.224354620999918 },
  { lat: 29.361273505000142, lng: -10.199126756999874 },
  { lat: 29.378973700000145, lng: -10.187570766999926 },
  { lat: 29.397284247000115, lng: -10.182728644999941 },
  { lat: 29.405829169000029, lng: -10.177642381999931 },
  { lat: 29.428615627000156, lng: -10.141753709999932 },
  { lat: 29.434963283000101, lng: -10.137033657999922 },
  { lat: 29.455959377000127, lng: -10.128163214999887 },
  { lat: 29.50995514500012, lng: -10.086984829999921 },
  { lat: 29.521144924000154, lng: -10.080922003999916 },
  { lat: 29.542303778000147, lng: -10.078684048999946 },
  { lat: 29.564683335000083, lng: -10.072621222999942 },
  { lat: 29.584947007, lng: -10.063628709999932 },
  { lat: 29.599920966000028, lng: -10.052967902999882 },
  { lat: 29.638413804000109, lng: -10.005279100999928 },
  { lat: 29.650824286000145, lng: -9.997792120999861 },
  { lat: 29.66681549700003, lng: -9.991688605999883 },
  { lat: 29.716009833000058, lng: -9.949940558999941 },
  { lat: 29.838934637000094, lng: -9.820220506999959 },
  { lat: 29.996568101000108, lng: -9.717925584999932 },
  { lat: 30.126898505000057, lng: -9.655140753999945 },
  { lat: 30.164007880000085, lng: -9.645253058999856 },
  { lat: 30.291408596000096, lng: -9.628407355999855 },
  { lat: 30.357814846000124, lng: -9.610015428999901 },
  { lat: 30.377630927000141, lng: -9.607329881999931 },
  { lat: 30.404364325000145, lng: -9.611643032999922 },
  { lat: 30.417425848000121, lng: -9.622710740999878 },
  { lat: 30.426662502000099, lng: -9.638172980999883 },
  { lat: 30.442206122000087, lng: -9.655140753999945 },
  { lat: 30.459295966000056, lng: -9.666249152999882 },
  { lat: 30.509588934000092, lng: -9.691761847999885 },
  { lat: 30.542710679000137, lng: -9.700062628999945 },
  { lat: 30.547512111000017, lng: -9.708404100999928 },
  { lat: 30.548529364000089, lng: -9.719553188999953 },
  { lat: 30.552069403000147, lng: -9.730824347999942 },
  { lat: 30.559881903000147, lng: -9.739816860999952 },
  { lat: 30.586818752000127, lng: -9.758168097999914 },
  { lat: 30.628607489000061, lng: -9.829660610999952 },
  { lat: 30.629624742000104, lng: -9.850941535999937 },
  { lat: 30.633978583000115, lng: -9.874012824999909 },
  { lat: 30.648260809000092, lng: -9.888539191999939 },
  { lat: 30.686672268000038, lng: -9.886708136999857 },
  { lat: 30.724269924000012, lng: -9.865630662999934 },
  { lat: 30.759833075000088, lng: -9.839019334999904 },
  { lat: 30.792303778000175, lng: -9.820220506999959 },
  { lat: 30.814683335, lng: -9.813710089999859 },
  { lat: 30.82713450700011, lng: -9.812896287999934 },
  { lat: 30.836655992000104, lng: -9.816802537999934 },
  { lat: 30.845648505000113, lng: -9.823841925999915 },
  { lat: 30.851955471000068, lng: -9.824370897999899 },
  { lat: 30.859116929000109, lng: -9.821888800999972 },
  { lat: 30.871079820000048, lng: -9.820220506999959 },
  { lat: 31.067450262000094, lng: -9.827707485999923 },
  { lat: 31.085353908000158, lng: -9.831450975999871 },
  { lat: 31.12128327000012, lng: -9.847523566999939 },
  { lat: 31.144761460000026, lng: -9.835031704999921 },
  { lat: 31.28620026200015, lng: -9.808949347999942 },
  { lat: 31.343451239000117, lng: -9.806630011999886 },
  { lat: 31.366848049000012, lng: -9.810902472999914 },
  { lat: 31.379461981000091, lng: -9.821522589999859 },
  { lat: 31.388820705000043, lng: -9.834787563999896 },
  { lat: 31.402411200000174, lng: -9.847523566999939 },
  { lat: 31.436835028000118, lng: -9.816232876999891 },
  { lat: 31.471340236000046, lng: -9.792958136999857 },
  { lat: 31.531480210000112, lng: -9.769642706999946 },
  { lat: 31.545803127000099, lng: -9.758168097999914 },
  { lat: 31.560003973, lng: -9.739613410999908 },
  { lat: 31.567368882000025, lng: -9.733265753999888 },
  { lat: 31.585150458, lng: -9.727650519999884 },
  { lat: 31.597845770000148, lng: -9.713612433999913 },
  { lat: 31.60932038, lng: -9.70763098899991 },
  { lat: 31.625718492000189, lng: -9.694203253999916 },
  { lat: 31.635199286, lng: -9.689279751999948 },
  { lat: 31.715399481000063, lng: -9.6752009759999 },
  { lat: 31.731146552, lng: -9.667307094999927 },
  { lat: 31.816107489000146, lng: -9.575795050999915 },
  { lat: 31.86448802300005, lng: -9.535389777999882 },
  { lat: 31.871242580000072, lng: -9.527943488999881 },
  { lat: 31.880845445000048, lng: -9.513295050999886 },
  { lat: 31.888413804000109, lng: -9.504953579999921 },
  { lat: 31.898138739000061, lng: -9.497425910999937 },
  { lat: 31.915350653000175, lng: -9.488677537999877 },
  { lat: 31.922552802000027, lng: -9.4837947259999 },
  { lat: 32.026312567, lng: -9.366118943999936 },
  { lat: 32.046698309000178, lng: -9.354074673999918 },
  { lat: 32.061509507000054, lng: -9.346424933999913 },
  { lat: 32.101629950000145, lng: -9.334828253999888 },
  { lat: 32.118068752000156, lng: -9.333078579999892 },
  { lat: 32.130438544000086, lng: -9.328114386999857 },
  { lat: 32.168931382, lng: -9.291981574999909 },
  { lat: 32.196600653000175, lng: -9.277740037999905 },
  { lat: 32.227484442000119, lng: -9.270008917999888 },
  { lat: 32.330633856000148, lng: -9.264759894999912 },
  { lat: 32.335516669000029, lng: -9.268950975999928 },
  { lat: 32.343491929, lng: -9.287749803999873 },
  { lat: 32.35081614799999, lng: -9.291981574999909 },
  { lat: 32.372463283000073, lng: -9.289662238999938 },
  { lat: 32.387762762000037, lng: -9.283355272999899 },
  { lat: 32.43256256700009, lng: -9.256255662999877 },
  { lat: 32.452541408000158, lng: -9.248850063999896 },
  { lat: 32.472316799000069, lng: -9.246937628999916 },
  { lat: 32.48826732, lng: -9.254790818999908 },
  { lat: 32.498480536000116, lng: -9.263661261999857 },
  { lat: 32.51292552299999, lng: -9.272368943999936 },
  { lat: 32.52952708500014, lng: -9.27830969999988 },
  { lat: 32.54633209800015, lng: -9.278960740999906 },
  { lat: 32.576890367000161, lng: -9.259917772999927 },
  { lat: 32.68284739800005, lng: -9.112172003999916 },
  { lat: 32.797105210000112, lng: -8.998524542999917 },
  { lat: 32.854803778000147, lng: -8.901682094999927 },
  { lat: 32.881903387000179, lng: -8.867787238999881 },
  { lat: 33.010646877000099, lng: -8.754709438999925 },
  { lat: 33.127630927000112, lng: -8.62913977799991 },
  { lat: 33.139349677000112, lng: -8.621490037999877 },
  { lat: 33.152004299000069, lng: -8.620350714999916 },
  { lat: 33.173529364, lng: -8.621001756999874 },
  { lat: 33.186265367000047, lng: -8.61188717399989 },
  { lat: 33.268703518000066, lng: -8.529164191999939 },
  { lat: 33.272528387000179, lng: -8.521148240999878 },
  { lat: 33.270493882000054, lng: -8.512521938999896 },
  { lat: 33.261542059000035, lng: -8.502797003999945 },
  { lat: 33.259507554000024, lng: -8.494130011999857 },
  { lat: 33.260646877000127, lng: -8.469797329999977 },
  { lat: 33.264227606000119, lng: -8.451649542999888 },
  { lat: 33.280015367000047, lng: -8.415028449999852 },
  { lat: 33.304673570000048, lng: -8.378773566999939 },
  { lat: 33.3653832050001, lng: -8.320220506999903 },
  { lat: 33.389837958000143, lng: -8.284657355999912 },
  { lat: 33.40452708500014, lng: -8.243153449999909 },
  { lat: 33.430853583000143, lng: -8.106556769999941 },
  { lat: 33.46641673400002, lng: -8.011545376999919 },
  { lat: 33.484808661000059, lng: -7.962554490999878 },
  { lat: 33.494574286, lng: -7.916249152999882 },
  { lat: 33.503363348000065, lng: -7.891428188999925 },
  { lat: 33.526556708, lng: -7.869740363999938 },
  { lat: 33.53595612200003, lng: -7.844797329999921 },
  { lat: 33.553290106000119, lng: -7.777984178999873 },
  { lat: 33.570624091000113, lng: -7.738189256999874 },
  { lat: 33.574204820000077, lng: -7.719471808999913 },
  { lat: 33.582749742000189, lng: -7.700998501999891 },
  { lat: 33.599920966000028, lng: -7.684437628999916 },
  { lat: 33.612697658000158, lng: -7.667551235999923 },
  { lat: 33.608343817000062, lng: -7.6478572259999 },
  { lat: 33.614569403000033, lng: -7.636219855999883 },
  { lat: 33.613226630000142, lng: -7.626616990999906 },
  { lat: 33.609361070000134, lng: -7.617258266999954 },
  { lat: 33.608343817000062, lng: -7.606190558999941 },
  { lat: 33.611558335000112, lng: -7.596669074999852 },
  { lat: 33.620754299000012, lng: -7.579253709999875 },
  { lat: 33.625555731000091, lng: -7.549956834999932 },
  { lat: 33.632635809000149, lng: -7.529123501999948 },
  { lat: 33.64154694200009, lng: -7.510243292999945 },
  { lat: 33.649969794000057, lng: -7.49705969999988 },
  { lat: 33.686509507, lng: -7.463286912999905 },
  { lat: 33.698228257000082, lng: -7.434315558999913 },
  { lat: 33.726996161000116, lng: -7.406361456999889 },
  { lat: 33.73248932500006, lng: -7.387115037999934 },
  { lat: 33.725043036000059, lng: -7.393950975999957 },
  { lat: 33.719305731000091, lng: -7.373850063999896 },
  { lat: 33.724676825000145, lng: -7.352609829999921 },
  { lat: 33.73110586100016, lng: -7.341135219999956 },
  { lat: 33.772406317, lng: -7.267486131999903 },
  { lat: 33.800930080000015, lng: -7.229318813999953 },
  { lat: 33.817694403000033, lng: -7.192941860999952 },
  { lat: 33.830064195000077, lng: -7.15257727799991 },
  { lat: 33.834865627000156, lng: -7.117014126999948 },
  { lat: 33.834906317000147, lng: -7.116566535999937 },
  { lat: 33.840277411, lng: -7.099476691999911 },
  { lat: 33.863674221000096, lng: -7.067860480999912 },
  { lat: 33.874253648000135, lng: -7.031809048999946 },
  { lat: 33.944159247000144, lng: -6.927357550999915 },
  { lat: 34.039618231000148, lng: -6.822092251999891 },
  { lat: 34.162583726000108, lng: -6.733754035999937 },
  { lat: 34.280503648000106, lng: -6.667795376999891 },
  { lat: 34.417710679000081, lng: -6.557932094999927 },
  { lat: 34.434719143000123, lng: -6.547759568999879 },
  { lat: 34.47402578300013, lng: -6.530913865999878 },
  { lat: 34.504787502000013, lng: -6.507923956999889 },
  { lat: 34.880804755, lng: -6.292103644999941 },
  { lat: 35.124579169000171, lng: -6.205393032999922 },
  { lat: 35.501166083000115, lng: -6.023915167999917 },
  { lat: 35.520209052000112, lng: -6.018544074999852 },
  { lat: 35.544623114000061, lng: -6.016672329999892 },
  { lat: 35.55882396, lng: -6.012318488999881 },
  { lat: 35.598537502000099, lng: -5.990834113999881 },
  { lat: 35.65253327, lng: -5.970041469999956 },
  { lat: 35.660956122000144, lng: -5.968251105999883 },
  { lat: 35.670233466000113, lng: -5.965240037999877 },
  { lat: 35.685492255000085, lng: -5.952056443999936 },
  { lat: 35.695379950000174, lng: -5.949045376999919 },
  { lat: 35.74237702, lng: -5.943104620999918 },
  { lat: 35.765611070000134, lng: -5.936187303999901 },
  { lat: 35.780747789000131, lng: -5.927235480999883 },
  { lat: 35.79734935099999, lng: -5.908029751999919 },
  { lat: 35.801988023000106, lng: -5.873402472999942 },
  { lat: 35.790920315000065, lng: -5.781402147999927 },
  { lat: 35.792792059000035, lng: -5.769276495999947 },
  { lat: 35.814886786000116, lng: -5.74233964799987 },
  { lat: 35.822658596000124, lng: -5.729888475999871 },
  { lat: 35.830633856000176, lng: -5.711740688999953 },
  { lat: 35.834295966000028, lng: -5.69351152299987 },
  { lat: 35.829169012000122, lng: -5.680897589999887 },
  { lat: 35.835882880000057, lng: -5.663441535999937 },
  { lat: 35.836371161000059, lng: -5.639719204999921 },
  { lat: 35.831529039000159, lng: -5.615956183999913 },
  { lat: 35.822333075000088, lng: -5.598988410999937 },
  { lat: 35.850978908000073, lng: -5.54002844999988 },
  { lat: 35.863267320000134, lng: -5.523264126999976 },
  { lat: 35.896958726000136, lng: -5.487456834999875 },
  { lat: 35.91034577000012, lng: -5.468861456999946 },
  { lat: 35.918524481000034, lng: -5.448109503999945 },
  { lat: 35.91810638300008, lng: -5.440027099999924 },
  { lat: 35.914416573000054, lng: -5.435451735999891 },
  { lat: 35.908955655000128, lng: -5.42349675299991 },
  { lat: 35.909398432000089, lng: -5.414198431999893 },
  { lat: 35.917073236000064, lng: -5.410361029999905 },
  { lat: 35.924748041000115, lng: -5.407999551999922 },
  { lat: 35.926519149000015, lng: -5.405490480999902 },
];

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent {

  @Input() startDate?: Date | null;
  @Input() endDate?: Date | null;
  @Output() startDatePickerEvent = new EventEmitter<Date>();
  @Output() endDatePickerEvent = new EventEmitter<Date>();
  dateErrorMessage: string | null = null;

  constructor() { }

  onStartDateChange() {
    // Handle start date change here
    const isDateRangeValid = this.validateStartDate();
    if (isDateRangeValid) {
      this.emitStartDateEvent(this.startDate!);
    }
  }

  onEndDateChange() {
    // Handle end date change here
    const isDateRangeValid = this.validateEndDate();
    if (isDateRangeValid) {
      this.emitEndDateEvent(this.endDate!);
    }
  }

  validateStartDate(): boolean {
    const date = new Date(this.startDate!.toLocaleString());
    const today = new Date();

    if (date && date > today) {
      this.dateErrorMessage = null;
      return true;
    } else {
      this.dateErrorMessage = "Date de départ doit être saisie et doit être supérieure a la date d'aujourd'hui";
      return false;
    }
  }

  validateEndDate(): boolean {
    if (this.validateStartDate()) {
      const startDate = new Date(this.startDate!.toLocaleString());
      const endDate = new Date(this.endDate!.toLocaleString());
      if (endDate == null || endDate < startDate!) {
        this.dateErrorMessage = "Date de fin doit être saisie et doit être supérieure a la date de départ";
        this.endDate = null;
        return false
      } else {
        this.dateErrorMessage = null;
        return true;
      }
    } else {
      this.endDate = null;
      return false;
    }
  }

  emitStartDateEvent = (date: Date) => this.startDatePickerEvent.emit(date);
  emitEndDateEvent = (date: Date) => this.endDatePickerEvent.emit(date);

}

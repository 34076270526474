<div  class="flex justify-center m-6">
  <label for="file-profile-pic-input" class="relative inline-block h-24 w-24 rounded-full bg-gray-100 cursor-pointer">
    <!-- <img *ngIf="picPath" [src]="picPath" class="absolute inset-0 h-full w-full rounded-full object-cover" alt="Profile Picture">    
    <img *ngIf="attachmentInput" [src]="picPath" class="absolute inset-0 h-full w-full rounded-full object-cover" alt="Profile Picture">     -->
   <!-- <app-picture classStyle="absolute inset-0 h-full w-full rounded-full object-cover" [attachment]="attachmentInput"  [downloadPicture]="false"></app-picture> -->
   <img *ngIf="attachmentInput && !picPath" class="absolute inset-0 h-full w-full rounded-full object-cover" [src]="attachmentInput.url" alt="" />
   <img *ngIf="picPath" class="absolute inset-0 h-full w-full rounded-full object-cover" [src]="picPath" alt="" />

   <div class="absolute inset-0 flex items-center justify-center">
      <mat-icon *ngIf="!picPath && !attachmentInput?.url" class="text-grey">{{ 'PROFILE_PICTURE.ADD_PHOTO_ICON' | transloco }}</mat-icon>
    </div>
    <div *ngIf="canEdit" class="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity rounded-full bg-black bg-opacity-50">
      <label for="file-profile-pic-input" class="cursor-pointer">
        <mat-icon  class="text-white">{{ 'PROFILE_PICTURE.EDIT_ICON' | transloco }}</mat-icon>
      </label>
    </div>
  </label>
  <input type="file" id="file-profile-pic-input" [disabled]="!canEdit" class="hidden" accept="image/*" (change)="onPicSelected($event)" />
</div>


import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AlertService {

  showAlert(icon: 'success' | 'error' | 'warning', title: string, text: string) {
    Swal.fire({
      icon: icon,
      title: title,
      text: text,
      cancelButtonColor: "#ef4444",
      confirmButtonColor: "#ef4444",
    });
  }

  showAlertWithConfirmation(icon: 'success' | 'error', title: string, text: string, el: any) {
    Swal.fire({
      icon: icon,
      title: title,
      text: text,
      cancelButtonColor: "#ef4444",
      confirmButtonColor: "#ef4444",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      // if (result.isConfirmed) {
      //   Swal.fire('Saved!', '', 'success')
      // } else if (result.isDenied) {
      //   Swal.fire('Changes are not saved', '', 'info')
      // }
      el();
    })
  }

  showAlertWithDuration(icon: 'success' | 'error' | 'warning', title: string, text: string) {
    Swal.fire({
      icon: icon,
      title: title,
      text: text,
      showConfirmButton: false,
      timer: 1500
    });
  }
  
}
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaginationService {
  
  currentPage: number = 0;
  currentScrollPage: number = 0;
  currentPageSize: number = 0;
  size: number = 10;

  from: number = 0;
  to: number = 0;

  totalElements: number = 0;
  totalPages: number = 0;

  leftNumbers: Array<number> = [];
  rightNumbers: Array<number> = [];

  public paginatingSubject: BehaviorSubject<number> = new BehaviorSubject(this.setPaginatingSubject);
  public scrollPaginatingSubject: BehaviorSubject<number> = new BehaviorSubject(this.setPaginatingSubject);

  constructor() { }

  public set setPaginatingSubject(page: number) {
    this.currentPage = page;
    this.paginatingSubject.next(page);
  }

  public set setScrollPaginatingSubject(page: number) {
    this.currentScrollPage = page;
    this.scrollPaginatingSubject.next(page);
  }

  paginationToString() {
    return "current Page " + this.currentPage + " size: " + this.size + " currentPage: " +
      this.currentPage + " totalElement: " + this.totalElements + " totalPages: " + this.totalPages;
  }

  setPaginationValues(paginatingObject: any) {
    this.initEnumeratedPagination({
      currentPageSize: paginatingObject.size,
      totalElements: paginatingObject.totalElements,
      totalPages: paginatingObject.totalPages,
    });
  }

  resetPageAndSize({ page = 0, size = 10 }) {
    this.currentPage = page;
    this.currentScrollPage = page;
    this.size = size;
  }

  /**
   * Summary. Init service
   * TODO. NG REDUX is a better solution for state management
   */
  init({ currentPageSize = 0, totalElements = 0, totalPages = 0 }) {
    if (totalElements) this.from = Math.round((totalElements / totalPages) * this.currentPage);
    if (totalElements) this.to = Math.round((totalElements / totalPages) * this.currentPage + 2);

    this.totalElements = totalElements;
    this.totalPages = totalPages;
    this.currentPageSize = currentPageSize;
  }

  initEnumeratedPagination({ currentPageSize = 0, totalElements = 0, totalPages = 0 }) {
    this.leftNumbers = [];
    this.rightNumbers = [];

    this.totalElements = totalElements;
    this.totalPages = totalPages;

    let leftPage = this.currentPage;
    let rightPage = this.currentPage;

    while (leftPage > 0 && leftPage <= this.currentPage && this.leftNumbers.length < 3) {
      leftPage--;
      this.leftNumbers.unshift(leftPage);
    }
    while (rightPage < this.totalPages - 1 && this.rightNumbers.length < 3) {
      rightPage++;
      this.rightNumbers.push(rightPage);
    }
  }

  next() {
    this.currentPage++;
  }

  previous() {
    this.currentPage--;
  }

  goToPage(page: number) {
    this.currentPage = page;
  }

}

<div class="map-container">
  <!-- <mat-slide-toggle  *ngIf="(isEdit || isCreate)" class="mb-2" [(ngModel)]="needToCorrectAdress" (change)="onAddressChange()">
    Saisie manuelle
  </mat-slide-toggle> -->
  <input (keyup)="correctAdress()" class="mb-2" [readonly]="isRead && !isEdit && !isCreate"
    [(ngModel)]="correctedAdresse" type="text" placeholder="{{ 'GOOGLEMAP.ADRESSE' | transloco }}" />

  <input [readonly]="!isEdit && !isCreate" class="mb-2 location_input" type="text" placeholder="{{ 'GOOGLEMAP.FIND_ADDRESS' | transloco }}"
    (input)="onAddressChange()" #addressText />

  <div class="map-frame">
    <google-map class="googlemap" width="100%" height="100%" [options]="options" (mapClick)="setMarker($event)">
      <map-polyline [path]="vertices" [options]="polyOptions"></map-polyline>
      <map-marker *ngIf="markerPosition" [position]="markerPosition" [options]="markerOptions"></map-marker>
    </google-map>
  </div>
</div>
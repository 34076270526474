import { Attachment } from "../models/attachment.model";

export class RequestDto {

    id?: number;
    category?: string;
    title?: string;
    description?: string;

    date?: Date;
    startDate?: Date;
    endDate?: Date;
    
    priority?: string;
    status?: string;
    
    userId?: number;
    userFirstName?: string;
    userLastName?: string;
    userPictureUrl?: string;
    userPhoneNumberPro?: string;

    attachment?: Attachment;
    profilePic?: Attachment;

}
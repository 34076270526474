import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'binaryToDataUrl'
})
export class BinaryToDataUrlPipe implements PipeTransform {

  constructor(public sanitizer: DomSanitizer){}

  transform(binaryData: ArrayBuffer): SafeUrl | null {
    if (!binaryData) {
      return null;
    }

    try {
      const byteArray = new Uint8Array(binaryData);
      const blob = new Blob([byteArray]); // Replace with the appropriate image type
      const objectUrl = URL.createObjectURL(blob);
      const imageSrc = this.sanitizer.bypassSecurityTrustUrl(objectUrl);

      return imageSrc;
    } catch (error) {
      // console.error('Error converting binary data to Data URL:', error);
      return null;
    }
  }

}

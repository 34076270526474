<div class="modal {{ size }}">
    <div class="modal-header mr-auto text-lg font-semibold">
        {{ title }}
        <span class="modal-close" (click)="close()">✕</span>
    </div>
    <div class="modal-content">
        <ng-content></ng-content>
    </div>
    <div class="modal-footer">
        <button (click)="submit()">{{ 'MODAL.SUBMIT_BUTTON' | transloco }}</button>
    </div>
</div>

<div class="modal-backdrop" (click)="close()"></div>
export class Page {

    totalElements: number = 0;
    totalPages: number = 0;
    empty: boolean = false;
    first: boolean = false;
    last: boolean = false;
    numberOfElements: number = 0;
    size: number = 0;
    number: number = 0;
    sort: any;
    
}
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { morrocanBorders } from './morrocan-borders';
import { Subscription } from 'rxjs';
import { GoogleMapService } from 'src/app/core/services/google-map.service';
import { initialPosition, mapOptions, markerOptions, polylineOptions } from './map-config';
import { GoogleMap } from '@angular/google-maps';
import { LocationDto } from 'src/app/core/dtos/location.dto';
import { GPlace } from 'src/app/core/models/location-details.model';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
})
export class GoogleMapComponent implements AfterViewInit, OnDestroy, OnInit {
  
  
  getInputValueForAutocompleteInput():string {
    var value: string="";
    if (this.location?.display_name) {
      value = this.location?.display_name;
    }
    if (this.selectedLocation?.display_name) {
      value = this.selectedLocation?.display_name;
    }
    return value;
  }

  @ViewChild('addressText') addressText!: ElementRef<HTMLInputElement>;
  @ViewChild(GoogleMap) map?: GoogleMap;

  protected placeSubscription?: Subscription;

  polyOptions: google.maps.PolylineOptions = polylineOptions;
  options: google.maps.MapOptions = 
  {
    streetViewControl: false,
  scrollwheel: false, // Disable scrollwheel zoom by default
  gestureHandling: 'cooperative' // Add cooperative gesture handling
  };
 
  vertices: google.maps.LatLngLiteral[] = morrocanBorders;
  markerOptions: google.maps.MarkerOptions = { draggable: false };
  markerPosition?: google.maps.LatLngLiteral;

  @Output() getLocation = new EventEmitter<GPlace>();
  @Input() location?: any;
  @Input() isEdit?: boolean = false;
  @Input() isRead?: boolean = true;
  @Input() isCreate?: boolean = false;

  selectedLocation?: any;
  needToCorrectAdress: boolean = false;
  correctedAdresse: string = '';

  
  constructor(private googleMapService: GoogleMapService,
    //do not remove this cdr even if its not used
    // because without it when we select adresse from autocomplete
    //the ui does not refresh
    private cdr: ChangeDetectorRef
    ) {}

  onAddressChange(): void {
    if (this.isCreate || this.isEdit) {
      if (this.placeSubscription) {
        this.placeSubscription.unsubscribe();
      }
      this.placeSubscription = this.googleMapService.placeObservable.subscribe((place) => {
        // this.setNewCenter(place.geometry?.location?.toJSON());
        let selectedLoc={lat:place.lat!,lng:place.lon!};
        let {lat,lng}=selectedLoc;
        this.map?.panTo(selectedLoc);
        this.markerPosition = {lat:lat!,lng:lng!};
        this.selectedLocation = {
          id:this.location?.id,
          lat:place.lat!.toString(),
          lon: place.lon!.toString(),
          display_name: place.display_name,
          address:{
            ...place.address,
            id: this.location?.address?.id?this.location?.address?.id:null
          }
        };
        this.correctedAdresse = place.display_name!;
        this.map?.panTo(selectedLoc)
        this.getLocation.emit(this.selectedLocation);
        this.cdr.detectChanges()
        this.map=this.map;
        
      });
    }
  }
  

  correctAdress() {
    if (this.isCreate || this.isEdit) {
        this.selectedLocation = {
          ...this.selectedLocation,
          display_name: this.correctedAdresse!,
        };
      console.log("location: ",this.selectedLocation.address);

      this.getLocation.emit(this.selectedLocation as GPlace);
    }
  }


  setMarker(event: google.maps.MapMouseEvent) {
    if (this.isCreate || this.isEdit) {
      this.markerPosition = event?.latLng!.toJSON();
      this.map?.panTo(this.markerPosition);
      this.selectedLocation = {
        id:this.location?.id,
        lat: this.markerPosition.lat.toString(),
        lon: this.markerPosition.lng.toString(),
        display_name: this.correctedAdresse,
        address: this.location?.address

      };
      this.getLocation.emit(this.selectedLocation as GPlace);
      
    }
  }

  ngOnInit(): void {
    if (this.location) {
      this.selectedLocation = this.location;
      if (this.location.display_name) this.correctedAdresse = this.location.display_name;
      this.markerPosition = {
        lat: parseFloat(this.location.lat),
        lng: parseFloat(this.location.lon),
      };

      this.options = {
        ...this.options,
        center: this.markerPosition,
      };
      
    }
  }

  ngOnDestroy(): void {
    this.placeSubscription?.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.googleMapService.getPlaceAutocomplete(this.addressText);
  
    // Enable scrollwheel when the map is interacted with
    this.map?.googleMap?.addListener('mouseenter', () => {
      this.map?.googleMap?.setOptions({scrollwheel: true});
    });
  
    // Disable scrollwheel when the interaction ends
    this.map?.googleMap?.addListener('mouseleave', () => {
      this.map?.googleMap?.setOptions({scrollwheel: false});
    });
  }
}

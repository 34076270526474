import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { File as LocalFile } from 'src/app/core/models/file.model';
import { Attachment } from '../models/attachment.model';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private httpService: HttpService) { }

  getFile(filePath: string): Promise<any> {
    return this.httpService.getFile('/v1.0/attachment/getFile', { 'filePath': filePath }).then(data => {
      return data;
    }).catch(error => {
      return error;
    })
  }

  downloadFile(filePath: string) {
    this.httpService.downloadFileFrom('/v1.0/attachment/getFile', { 'filePath': filePath }).then((data: Blob | undefined) => {
      if(!data) return;
      
      const blob = new Blob([data], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);

      // Create a link element
      const a = document.createElement('a');
      a.href = url;
      a.download = filePath; // Set the filename

      // Trigger a click on the link to start the download
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
    }).catch((error) => {
      console.error('Error downloading file:', error);
    });
  }

  async onFileSelected(event: any, fileGroup: string): Promise<LocalFile | undefined> {
    const fileInput = event.target;
    const file: File = fileInput.files[0];
    let localFile: LocalFile | undefined = undefined;
    if (file) {
      let attachment: Attachment = new Attachment();
      attachment.type = file.type;
      attachment.name = file.name;
      attachment.attachmentGroup = fileGroup
      attachment.fileData = Array.from(new Uint8Array(await file.arrayBuffer()));
      localFile = new LocalFile();
      localFile.startDate = new Date();
      localFile.attachment = attachment;
    }

    return localFile;
  }

}

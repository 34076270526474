import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { NotificationDto } from '../dtos/notification.dto';
import { AuthService } from './auth.service';
import { PaginationService } from './pagination.service';
import { BehaviorSubject } from 'rxjs';
import { NotificationPage } from '../display-models/notification-page.model';
import { RequestModalComponent } from 'src/app/modules/request/components/request-modal/request-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RequestService } from './request.service';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private client?: WebSocket;
  private notification?: NotificationDto;

  public notificationPageChanged: BehaviorSubject<NotificationPage> = new BehaviorSubject(this.setNotificationPageChanged);
  public countUnreadChanged: BehaviorSubject<number> = new BehaviorSubject(this.setCountUnreadChanged);

  showToast: boolean = false;

  constructor(private httpService: HttpService, private authService: AuthService, private paginationService: PaginationService,
    private dialog: MatDialog, private requestService: RequestService) {
  }

  public get getNotification(): NotificationDto | undefined {
    return this.notification;
  }

  public set setNotificationPageChanged(value: NotificationPage) {
    this.notificationPageChanged.next(value);
  }

  public set setCountUnreadChanged(value: number) {
    this.countUnreadChanged.next(value);
  }

  // Method to increment the value
  public incrementCountUnreadChanged(): void {
    // Get the current value
    const currentValue: number = this.countUnreadChanged.getValue();

    // Increment the value
    const newValue: number = currentValue + 1;

    // Update the BehaviorSubject with the new value
    this.countUnreadChanged.next(newValue);
  }

  // Method to increment the value
  public decrementCountUnreadChanged(): void {
    // Get the current value
    const currentValue: number = this.countUnreadChanged.getValue();

    // Increment the value
    const newValue: number = currentValue - 1;

    // Update the BehaviorSubject with the new value
    this.countUnreadChanged.next(newValue);
  }



  connect() {

    if (this.client?.OPEN) return;

    let token = this.authService.getToken();
    if (token==null || token=='') return;

    this.client = new WebSocket(`ws://localhost:8097/ws/notifications/request?token=${token}`);
    this.client.onopen = (event) => {
      console.log('--- on open ---');
      console.log(event);
    }
    this.client.onclose = (event) => {
      console.log('--- on close ---');
      console.log(event);
    };
    this.client.onmessage = (event) => {
      console.log('--- on message ---');
      this.incrementCountUnreadChanged();
      this.notification = JSON.parse(event.data);
      this.showToast = true;
    };
  }

  getNotifications(userId: number): Promise<any> {
    let params: any = { userId: userId, page: this.paginationService.currentPage, size: this.paginationService.size };
    return this.httpService.get('/v1.0/notification/search', params).then((data: any) => {
      if (data) this.setNotificationPageChanged = data;
      else this.setNotificationPageChanged = new NotificationPage();
    }).catch(error => {
      console.log(error);
    });
  }

  countUnreadByUserId(userId: number) {
    let params: any = { userId: userId };
    this.httpService.get('/v1.0/notification/countUnread', params).then((data: any) => {
      this.setCountUnreadChanged = data;
    }).catch(error => {
      console.log(error);
    });
  }

  setNotificationToOpened(notificationId: number) {
    let params: any = { notificationId: notificationId };
    this.httpService.get('/v1.0/notification/openNotification', params).then((data: any) => {
      this.decrementCountUnreadChanged();
      this.updateObjectById(notificationId);
    }).catch(error => {
      console.log(error);
    });
  }

  // Function to update an object by its ID
  updateObjectById(notificationId: number) {
    let notificationPage: NotificationPage = this.notificationPageChanged.getValue();

    // Find the index of the object with the given ID
    const index = notificationPage.content.findIndex(obj => obj.id === notificationId);

    // If the object with the given ID exists
    if (index !== -1) {
      // Update the object with the new data
      notificationPage.content[index].opened = true;
      this.setNotificationPageChanged = notificationPage;
    }
  }

  toggleToast() {
    this.showToast = !this.showToast;
    if (this.showToast) {
      setTimeout(() => {
        this.showToast = false;
      }, 3000); // Hide toast after 3 seconds
    }
  }

  openNotification() {
    this.getRequestDetail();
  }

  openDialog(data: any) {
    const dialogRef: MatDialogRef<RequestModalComponent> = this.dialog.open(RequestModalComponent, {
      data: data, // Pass your data here
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  getRequestDetail() {
    this.requestService.retrieveRequestById(this.notification!.objectId!)
      .then(data => {
        this.openDialog(data);
        if (!this.notification?.opened) this.setNotificationToOpened(this.notification!.id!);
      }).catch(error => {
        console.log(error);
      });
    this.toggleToast();
  }

  closeNotification(): void {
    this.showToast = false;
  }


}

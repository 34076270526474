<div class="inline-flex flex-row w-full space-x-4">
    <div class="basis-1/2 w-full">
        <!-- Start Date Input -->
        <input type="datetime-local" class="date-picker-request w-full border rounded-md focus:ring focus:ring-blue-200"
            placeholder="{{ 'DATE_INPUTS.Start_Date' | transloco }}" [(ngModel)]="startDate" (change)="onStartDateChange()" />
    </div>
    <div class="basis-1/2 w-full">
        <!-- End Date Input -->
        <input type="datetime-local" class= " date-picker-request w-full border rounded-md focus:ring focus:ring-blue-200"
            placeholder="{{ 'DATE_INPUTS.End_Date' | transloco }}" [(ngModel)]="endDate" (change)="onEndDateChange()" />
    </div>
</div>
<!-- Error message -->
<p *ngIf="dateErrorMessage" class="mt-4 text-xs text-red-500">
    {{ dateErrorMessage }}
</p>
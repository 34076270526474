<!-- Toast -->
<div *ngIf="notificationService.showToast" [@fadeInOut]
    class="max-w-xs p-4 bg-white border border-gray-200 rounded-xl shadow-lg dark:bg-gray-800 dark:border-gray-700 toast"
    role="alert">
    <div class="flex">
        <div class="flex-shrink-0">
            <svg class="size-5 text-gray-600 mt-1 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" width="24"
                height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round">
                <path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9" />
                <path d="M10.3 21a1.94 1.94 0 0 0 3.4 0" />
            </svg>
        </div>
        <div class="ms-4">
            <h3 class="text-gray-800 font-semibold dark:text-white">
                {{ notificationService.getNotification?.title }}
            </h3>
            <div class="mt-1 text-sm text-gray-600 dark:text-gray-400">
                {{ notificationService.getNotification?.message }}
            </div>
            <div class="mt-4">
                <div class="flex space-x-3">
                    <!-- <button type="button"
                        class="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:text-blue-800 dark:text-blue-500 dark:focus:text-blue-400">
                        Don't allow
                    </button> -->
                    <button type="button" (click)="notificationService.openNotification()"
                        class="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:text-blue-800 dark:text-blue-500 dark:focus:text-blue-400">
                        Afficher
                    </button>
                </div>
            </div>
        </div>
        <div class="lex-shrink-0" (click)="notificationService.closeNotification()">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
        </div>
    </div>
</div>
<!-- End Toast -->
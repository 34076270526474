import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponsiveHelperComponent } from './components/responsive-helper/responsive-helper.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationComponent } from './components/pagination/pagination.component';
import { EnumeratedPaginationComponent } from './components/enumerated-pagination/enumerated-pagination.component';
import { AutoCompletInputComponent } from './components/auto-complet-input/auto-complet-input.component';
import { StarsComponent } from './components/stars/stars.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TwoDigitPipe } from './pipes/two-digit.pipe';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { LeafletMapComponent } from './components/leaflet-map/leaflet-map.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { BinaryToDataUrlPipe } from './pipes/binary-to-data-url.pipe';
import { ProfileCardComponent } from './components/profile-card/profile-card.component';
import { RouterModule } from '@angular/router';
import { ModalComponent } from './components/modal/modal.component';
import { ProfileSecondCardComponent } from './components/profile-card2/profile-second-card.component';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { SecondPaginationComponent } from './components/second-pagination/second-pagination.component';
import { MatIconModule } from '@angular/material/icon';
import { BadgeComponent } from './badge/badge.component';
import { GoogleMapComponent } from './components/google-map/google-map.component';
import { PictureComponent } from './components/picture/picture.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslocoModule } from '@ngneat/transloco';
import { MonthPickerComponent } from './components/month-picker/month-picker.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input'; // Add this line
import { MatDatepickerModule } from '@angular/material/datepicker'; // Add this line
import { MatNativeDateModule } from '@angular/material/core';
import { ToastComponent } from './components/toast/toast.component';
import { DateRangePickerV2Component } from './components/date-range-picker-v2/date-range-picker-v2.component';
import { FileViewerComponent } from './components/file-viewer/file-viewer.component';

@NgModule({
  declarations: [
    ResponsiveHelperComponent, 
    ClickOutsideDirective, 
    DatePickerComponent, 
    PaginationComponent, 
    EnumeratedPaginationComponent, 
    AutoCompletInputComponent, 
    StarsComponent, 
    SpinnerComponent, 
    TwoDigitPipe, 
    FileUploadComponent, 
    LeafletMapComponent, 
    PictureComponent, 
    DateRangePickerComponent, 
    BinaryToDataUrlPipe,
    ProfileCardComponent,
    ModalComponent,
    ProfileSecondCardComponent,
    UploadImageComponent,
    SecondPaginationComponent,
    BadgeComponent,
    GoogleMapComponent,
    MonthPickerComponent,  
    ToastComponent,
    DateRangePickerV2Component,
    FileViewerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MatIconModule,
    GoogleMapsModule,
    MatSlideToggleModule,
    TranslocoModule,
    MatFormFieldModule, 
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  exports: [
    ResponsiveHelperComponent, 
    ClickOutsideDirective, 
    DatePickerComponent, 
    PaginationComponent,
    EnumeratedPaginationComponent,
    AutoCompletInputComponent,
    StarsComponent,
    SpinnerComponent,
    TwoDigitPipe,
    FileUploadComponent,
    LeafletMapComponent,
    PictureComponent,
    DateRangePickerComponent,
    BinaryToDataUrlPipe,
    ProfileCardComponent,
    ProfileSecondCardComponent,
    UploadImageComponent,
    SecondPaginationComponent,
    BadgeComponent,
    GoogleMapComponent,
    MonthPickerComponent,
    ToastComponent,
    DateRangePickerV2Component,
    FileViewerComponent
  ],
})
export class SharedModule {}

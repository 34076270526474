export const mapOptions: google.maps.MapOptions = {
  center: { lat: 33.589886, lng: -7.603869 },
  zoom: 12,
  mapTypeId: google.maps.MapTypeId.ROADMAP, // Set map type to ROADMAP
  mapTypeControl: false, // Hide the map type control (Map/Satellite buttons)
  fullscreenControl: false, // Hide the fullscreen control button

  styles: [
    {
      featureType: 'administrative.country',
      elementType: 'geometry.stroke',
      stylers: [
        { visibility: 'off' }, // Hide country borders
      ],
    },
    {
      featureType: 'administrative.country',
      elementType: 'labels.text.fill',
      stylers: [
        { visibility: 'off' }, // Hide country labels
      ],
    },
  ],
};

export const polylineOptions: google.maps.PolylineOptions = {
  strokeWeight: 1,
  strokeColor: 'grey',
};
export const initialPosition: google.maps.LatLngLiteral | null = { lat: 33.589886, lng: -7.603869 };

export const markerOptions: google.maps.MarkerOptions = {
  position: initialPosition,
  
};

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { GPlace } from '../models/location-details.model';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapService {
  constructor() {}

  public placeSubject: Subject<GPlace> = new Subject<GPlace>();
  public placeObservable = this.placeSubject.asObservable();
  getPlaceAutocomplete(addressText: any): any {
    const autocomplete = new google.maps.places.Autocomplete(addressText.nativeElement, {
      componentRestrictions: { country: ['MA'] },
      types: ['establishment', 'geocode'],
      fields:[
        "address_component", "adr_address", "business_status", "formatted_address", "geometry", "icon", "name", "place_id", "plus_code", "type", "url"
      ]
    });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place =  autocomplete.getPlace();
      
      let gplace = this.getDetails(place);
      
      // autocomplete.getPlace();
      if(gplace)
      this.placeSubject.next(gplace);
    });
  }

  getDetails(place: google.maps.places.PlaceResult): GPlace | undefined {
    if (place && place.geometry) {
      const addressComponents = place.address_components;
      const tempPlace: GPlace = { address: {} };
  
      tempPlace.display_name = place.formatted_address;
  
      if (place.geometry.location) {
        tempPlace.lat = place.geometry.location.lat();
        tempPlace.lon = place.geometry.location.lng();
      }
  
      if(addressComponents?.length)
      for (const component of addressComponents) {
        switch (true) {
          case component.types.includes('route') || component.types.includes('street_number'):
            tempPlace.address.road = component.long_name;
            break;
          case component.types.includes('locality'):
            tempPlace.address.state = component.long_name;
            break;
          case component.types.includes('postal_code'):
            tempPlace.address.postcode = component.long_name;
            break;
          case component.types.includes('country'):
            tempPlace.address.country = component.long_name;
            tempPlace.address.country_code = component.short_name;
            break;
          default:
            break;
        }
      }
      return tempPlace;
    }
  
    return undefined;
  }

  getAddrComponent(place: any, componentTemplate: any): any {
    let result;
    for (const component of place.address_components) {
      const addressType = component.types[0];
      if (componentTemplate[addressType]) {
        result = component[componentTemplate[addressType]];
        return result;
      }
    }
  }
  getStreetNumber(place: any): any {
    const COMPONENT_TEMPLATE = { street_number: 'short_name' };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }
  getStreet(place: any): any {
    const COMPONENT_TEMPLATE = { route: 'long_name' };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }
  getCity(place: any): any {
    const COMPONENT_TEMPLATE = { locality: 'long_name' };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }
  getState(place: any): any {
    const COMPONENT_TEMPLATE = { administrative_area_level_1: 'short_name' };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }
  getDistrict(place: any): any {
    const COMPONENT_TEMPLATE = { administrative_area_level_2: 'short_name' };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }
  getCountryShort(place: any): any {
    const COMPONENT_TEMPLATE = { country: 'short_name' };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }
  getCountry(place: any): any {
    const COMPONENT_TEMPLATE = { country: 'long_name' };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }
  getPostCode(place: any): any {
    const COMPONENT_TEMPLATE = { postal_code: 'long_name' };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }
  getPhone(place: any): any {
    const COMPONENT_TEMPLATE = { formatted_phone_number: 'formatted_phone_number' };
    return this.getAddrComponent(place, COMPONENT_TEMPLATE);
  }
}



<div class="fixed inset-0 z-50 flex items-center justify-center px-4 py-4 overflow-auto bg-gray-900 bg-opacity-50">
    <div class="w-full max-w-2xl mx-auto overflow-hidden rounded-lg bg-white shadow-xl">
        <div class="px-6 py-4">
            <form [formGroup]="requestForm">
                <!-- Modal Header -->
                <div class="flex items-center justify-between">
                    <h2 class="text-lg font-semibold text-gray-800">{{ 'REQUEST_MODAL.NEW_REQUEST' | transloco }}</h2>
                    <button type="button" (click)="closeDialog()" class="text-gray-500 hover:text-gray-700">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                <!-- Modal Body with Scrollable Content -->
                <div class="mt-4 max-h-[70vh] overflow-y-auto">
                    <!-- Category Selection -->
                    <div class="mb-4">
                        <label class="block text-sm font-medium text-gray-700">{{ 'REQUEST_MODAL.CATEGORY_OF_REQUEST' | transloco }}</label>
                        <select formControlName="category" [disabled]="isFormDisabled" class="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                            <option *ngFor="let categoryKey of requestService.getCategoryKeys()" [value]="categoryKey" [disabled]="isFormDisabled">
                                {{requestService.categoryMap[categoryKey] }}
                            </option>
                        </select>
                        <p class="mt-3 hidden text-xs text-red-500" id="error">{{ 'REQUEST_MODAL.FILL_IN_FIELD' | transloco }}</p>
                    </div>

                    <!-- Conditional Date Picker -->
                    <div *ngIf="['HOLIDAY'].includes(requestForm.value.category)" class="mb-4">
                        <label class="block text-sm font-medium text-gray-700">{{ 'REQUEST_MODAL.DATE' | transloco }}</label>
                        <app-date-range-picker  [startDate]="request?.startDate" [endDate]="request?.endDate" (startDatePickerEvent)="onDatePickerEmitEvent('start', $event)" (endDatePickerEvent)="onDatePickerEmitEvent('end', $event)"></app-date-range-picker>
                    </div>

                    <!-- Priority Selection -->
                    <div class="mb-4" *ngIf="canManageRequests">
                        <label class="block text-sm font-medium text-gray-700">{{ 'REQUEST_MODAL.PRIORITY_OF_REQUEST' | transloco }}</label>
                        <select formControlName="priority" [disabled]="isFormDisabled" class="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                            <option *ngFor="let priorityKey of requestService.getPriorityKeys()" [value]="priorityKey" [disabled]="isFormDisabled">
                                {{requestService.priorityMap[priorityKey]}}
                            </option>
                        </select>
                        <p class="mt-3 hidden text-xs text-red-500" id="error">{{ 'REQUEST_MODAL.FILL_IN_FIELD' | transloco }}</p>
                    </div>

                    <!-- Request Title -->
                    <div class="mb-4">
                        <label class="block text-sm font-medium text-gray-700">{{ 'REQUEST_MODAL.SUBJECT_OF_REQUEST' | transloco }}</label>
                        <input id="title" type="text" formControlName="title" placeholder="{{ 'REQUEST_MODAL.SUBJECT_OF_REQUEST' | transloco }}" [disabled]="isFormDisabled"  class="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />
                        <p class="mt-3 hidden text-xs text-red-500" id="error">{{ 'REQUEST_MODAL.FILL_IN_FIELD' | transloco }}</p>
                    </div>

                    <!-- Request Description -->
                    <div class="mb-4">
                        <label class="block text-sm font-medium text-gray-700">{{ 'REQUEST_MODAL.DESCRIPTION' | transloco }}</label>
                        <textarea id="description" formControlName="description" rows="4" placeholder="{{ 'REQUEST_MODAL.DESCRIPTION' | transloco }}" [disabled]="isFormDisabled"  class="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"></textarea>
                        <p class="mt-3 hidden text-xs text-red-500" id="error">{{ 'REQUEST_MODAL.FILL_IN_FIELD' | transloco }}</p>
                    </div>

                    <!-- File Upload -->
                    <div  *ngIf="!request?.attachment"  class="mb-4">
                        <label class="block text-sm font-medium text-gray-700" for="file_input">{{ 'REQUEST_MODAL.SELECT_FILE' | transloco }}</label>
                        <input type="file" id="file_input" (change)="onFileSelected($event)" [disabled]="isFormDisabled" class="block w-full mt-1 text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold cursor-pointer file:bg-red-50 file:text-red-500 hover:file:bg-red-100" />
                    </div>

                    <!-- Attached File Display -->
                    <div *ngIf="request?.attachment" class="mb-4">
                        <!-- <app-file-upload [attachment]="request?.attachment"></app-file-upload> -->
                        <app-file-viewer [attachment]="request?.attachment"></app-file-viewer>
                    </div>
                    

                    <!-- Status Selection -->
                    <div class="mb-4">
                        <label class="block text-sm font-medium text-gray-700">{{ 'REQUEST_MODAL.STATUS' | transloco }}</label>
                        <select formControlName="status" [disabled]="!canManageRequests" class="block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                            <option *ngFor="let statusKey of requestService.getStatusKeys()" [value]="statusKey" [disabled]="!canManageRequests">
                                {{requestService.statusMap[statusKey]}}
                            </option>
                        </select>
                    </div>
                </div>

                <!-- Modal Footer -->
                <div class="flex justify-end mt-6 space-x-3">
                    <button type="button" (click)="closeDialog()" class="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300">
                        {{ 'REQUEST_MODAL.CLOSE' | transloco }}
                    </button>
                    <button type="submit" (click)="onSaveRequest()" class="px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300">
                        {{ 'REQUEST_MODAL.SAVE' | transloco }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

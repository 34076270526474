import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Attachment } from 'src/app/core/models/attachment.model';
import { File as LocalFile } from 'src/app/core/models/file.model';
import { FileService } from 'src/app/core/services/file.service';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent {

  @Output() removeEvent = new EventEmitter<number>();
  @Output() downloadEvent = new EventEmitter<number>();
  @Input() elementIndex?: number;
  @Input() attachment?: Attachment;
  @Input() hasRemoveButton: Boolean = false;

  constructor(public fileService: FileService) {
    console.log(this.attachment);
  }


  /**
   * Emit remove event
   * @returns
   */
  emitRemoveEvent = () => this.removeEvent.emit(this.elementIndex);

  /**
   * Emit remove event
   * @returns
   */
  emitDownloadEvent = () => this.removeEvent.emit(this.elementIndex);

  containsAny(types: any[]): boolean {
    return types.some(type => this.attachment?.type?.includes(type));
  }

  removeAttachement() {
    this.emitRemoveEvent();
  }

  downloadAttachement() {
    this.emitDownloadEvent();
  }

}

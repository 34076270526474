export class Attachment {

    id?: number;
    name?: string;
    type?: string;
    attachmentGroup?: string;
    url?: string;

    fileData?: number[]; // byte array

}
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import * as LEAFLET from 'leaflet';

@Component({
  selector: 'app-leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrls: ['./leaflet-map.component.scss'],
})
export class LeafletMapComponent implements OnInit, OnChanges {
  
  private map?: L.Map;
  private centroid: L.LatLngExpression = [33.589886, -7.603869]; //
  private marker?: L.Marker;
  @Output() latLngEvent = new EventEmitter<L.LatLng>();
  @Input() location?: any;
  @Input() readOnly?: boolean = false;

  latLng?: L.LatLng;
  private geocoder: any;
  searchAddress: string = ''; // Holds the input address

  constructor() { }

  ngOnInit(): void {
    this.initMap();
  }

  private initMap(): void {
    const faviconIcon = LEAFLET.icon({
      iconUrl: 'assets/icons/pin.svg', // Path to your favicon icon
      iconSize: [32, 32], // Size of the icon
      iconAnchor: [16, 16], // Anchor point of the icon
      popupAnchor: [0, -16], // Popup anchor
    });

    this.map = LEAFLET.map('map', {
      center: this.centroid,
      zoom: 12,
    });
    // this.marker.
    const tiles = LEAFLET.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 10,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });

    tiles.addTo(this.map);
    this.map.on('click', (event: L.LeafletMouseEvent) => {
      if (!this.readOnly) {
        this.latLng = event.latlng;
        this.latLngEvent.emit(event.latlng);
        if (this.marker) {
          this.map!.removeLayer(this.marker);
        }
        this.marker = LEAFLET.marker(this.latLng, { icon: faviconIcon }).addTo(this.map!);
      }
    });

    if (this.location) {
      this.marker = LEAFLET.marker(new LEAFLET.LatLng(this.location.lat, this.location.lon), { icon: faviconIcon }).addTo(
        this.map!,
      );
    }
  }

  emitEvent = (latLng: L.LatLng) => this.latLngEvent.emit(latLng);
  
  ngOnChanges(changes: SimpleChanges): void {
    const faviconIcon = LEAFLET.icon({
      iconUrl: 'assets/icons/pin.svg', // Path to your favicon icon
      iconSize: [32, 32], // Size of the icon
      iconAnchor: [16, 16], // Anchor point of the icon
      popupAnchor: [0, -16], // Popup anchor
    });
    if (this.location) {
      this.marker = LEAFLET.marker(new LEAFLET.LatLng(this.location.lat, this.location.lon), { icon: faviconIcon }).addTo(
        this.map!,
      );
    }
  }

}

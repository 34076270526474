import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-stars',
  templateUrl: './stars.component.html',
  styleUrls: ['./stars.component.scss']
})
export class StarsComponent {

  numberOfStars: number = 10;
  @Input() note?: number;

  constructor() {}

  checkStart = (note: number) => {
    if(this.note!= undefined && this.note > note) {
      return 'text-yellow-400';
    } else return 'text-gray-300'
  }

  getStartSize = (index: number) => {
    const nums = [ 0, 1, 8, 9];
    const nums2 = [ 2, 7];
    if(nums.includes(index)) return 'w-3 h-3';
    else if(nums2.includes(index)) return 'w-4 h-4';
    else return 'w-5 h-5';
  }

}

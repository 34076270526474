<div *ngIf="attachment?.name"
    class="flex flex-col items-center justify-center p-3 bg-gray-50 rounded-lg border border-dashed border-gray-300">
    <div class="flex flex-col sm:flex-row items-center justify-between w-full px-4 py-2">
        <div class="flex items-center space-x-4">
            <!-- Dynamic SVG icon based on file type -->
            <svg height="30px" width="80px" *ngIf="containsAny(['pdf'])" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 64 64" id="pdf-file">
                <path fill="#edeeef"
                    d="M53.42,10.15v43.7a2,2,0,0,1-2,2H20.14a2,2,0,0,1-2-2V23.09l11-14.94H51.42A2,2,0,0,1,53.42,10.15Z">
                </path>
                <path fill="#cfd0d1" d="M29.14,8.17V21a2,2,0,0,1-2,2H18.19Z"></path>
                <rect width="31.32" height="14.04" x="10.58" y="35.47" fill="#ff3717" rx="2"></rect>
                <path fill="#edeeef"
                    d="M20.74 43.3v2.16H19V39.53H21.4a3.28 3.28 0 0 1 1.37.22 1.71 1.71 0 0 1 .75.66 1.9 1.9 0 0 1 .27 1 1.76 1.76 0 0 1-.56 1.37 2.13 2.13 0 0 1-1.51.52zm0-1.27h.58c.51 0 .76-.2.76-.59s-.23-.56-.7-.56h-.64zM24.44 45.46V39.53h2.19a2.56 2.56 0 0 1 2.13.88 3.16 3.16 0 0 1 .71 2.05 3 3 0 0 1-.76 2.16 2.55 2.55 0 0 1-2 .84zm1.74-1.37h.45a.85.85 0 0 0 .73-.44 2.16 2.16 0 0 0 .29-1.19 2.34 2.34 0 0 0-.26-1.12.83.83 0 0 0-.76-.46h-.45zM33.74 43.24H31.9v2.22H30.21V39.53H34.3v1.41H31.9v1h1.84z">
                </path>
            </svg>
            <svg *ngIf="containsAny(['image'])" height="30px" width="80px" version="1.1" id="Layer_1"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512"
                xml:space="preserve">
                <path style="fill:#E2E5E7;"
                    d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z" />
                <path style="fill:#B0B7BD;" d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z" />
                <polygon style="fill:#CAD1D8;" points="480,224 384,128 480,128 " />
                <path style="fill:#A066AA;" d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16
            V416z" />
                <g>
                    <path style="fill:#FFFFFF;" d="M92.816,303.152c0-4.224,3.312-8.848,8.688-8.848h29.568c16.624,0,31.6,11.136,31.6,32.496
                c0,20.224-14.976,31.472-31.6,31.472H109.68v16.896c0,5.648-3.552,8.832-8.176,8.832c-4.224,0-8.688-3.184-8.688-8.832
                C92.816,375.168,92.816,303.152,92.816,303.152z M109.68,310.432v31.856h21.376c8.56,0,15.344-7.552,15.344-15.488
                c0-8.96-6.784-16.368-15.344-16.368L109.68,310.432L109.68,310.432z" />
                    <path style="fill:#FFFFFF;" d="M178.976,304.432c0-4.624,1.024-9.088,7.68-9.088c4.592,0,5.632,1.152,9.072,4.464l42.336,52.976
                v-49.632c0-4.224,3.696-8.848,8.064-8.848c4.608,0,9.072,4.624,9.072,8.848v72.016c0,5.648-3.456,7.792-6.784,8.832
                c-4.464,0-6.656-1.024-10.352-4.464l-42.336-53.744v49.392c0,5.648-3.456,8.832-8.064,8.832s-8.704-3.184-8.704-8.832v-70.752
                H178.976z" />
                    <path style="fill:#FFFFFF;" d="M351.44,374.16c-9.088,7.536-20.224,10.752-31.472,10.752c-26.88,0-45.936-15.36-45.936-45.808
                c0-25.84,20.096-45.92,47.072-45.92c10.112,0,21.232,3.456,29.168,11.264c7.808,7.664-3.456,19.056-11.12,12.288
                c-4.736-4.624-11.392-8.064-18.048-8.064c-15.472,0-30.432,12.4-30.432,30.432c0,18.944,12.528,30.448,29.296,30.448
                c7.792,0,14.448-2.304,19.184-5.76V348.08h-19.184c-11.392,0-10.24-15.632,0-15.632h25.584c4.736,0,9.072,3.6,9.072,7.568v27.248
                C354.624,369.552,353.616,371.712,351.44,374.16z" />
                </g>
                <path style="fill:#CAD1D8;"
                    d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z" />
            </svg>
            <svg *ngIf="containsAny(['xls','number','sheet'])" width="80px" height="30px" viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <defs>
                    <linearGradient id="a" x1="4.494" y1="-2092.086" x2="13.832" y2="-2075.914"
                        gradientTransform="translate(0 2100)" gradientUnits="userSpaceOnUse">
                        <stop offset="0" stop-color="#18884f" />
                        <stop offset="0.5" stop-color="#117e43" />
                        <stop offset="1" stop-color="#0b6631" />
                    </linearGradient>
                </defs>
                <title>file_type_excel</title>
                <path
                    d="M19.581,15.35,8.512,13.4V27.809A1.192,1.192,0,0,0,9.705,29h19.1A1.192,1.192,0,0,0,30,27.809h0V22.5Z"
                    style="fill:#185c37" />
                <path d="M19.581,3H9.705A1.192,1.192,0,0,0,8.512,4.191h0V9.5L19.581,16l5.861,1.95L30,16V9.5Z"
                    style="fill:#21a366" />
                <path d="M8.512,9.5H19.581V16H8.512Z" style="fill:#107c41" />
                <path d="M16.434,8.2H8.512V24.45h7.922a1.2,1.2,0,0,0,1.194-1.191V9.391A1.2,1.2,0,0,0,16.434,8.2Z"
                    style="opacity:0.10000000149011612;isolation:isolate" />
                <path d="M15.783,8.85H8.512V25.1h7.271a1.2,1.2,0,0,0,1.194-1.191V10.041A1.2,1.2,0,0,0,15.783,8.85Z"
                    style="opacity:0.20000000298023224;isolation:isolate" />
                <path d="M15.783,8.85H8.512V23.8h7.271a1.2,1.2,0,0,0,1.194-1.191V10.041A1.2,1.2,0,0,0,15.783,8.85Z"
                    style="opacity:0.20000000298023224;isolation:isolate" />
                <path d="M15.132,8.85H8.512V23.8h6.62a1.2,1.2,0,0,0,1.194-1.191V10.041A1.2,1.2,0,0,0,15.132,8.85Z"
                    style="opacity:0.20000000298023224;isolation:isolate" />
                <path
                    d="M3.194,8.85H15.132a1.193,1.193,0,0,1,1.194,1.191V21.959a1.193,1.193,0,0,1-1.194,1.191H3.194A1.192,1.192,0,0,1,2,21.959V10.041A1.192,1.192,0,0,1,3.194,8.85Z"
                    style="fill:url(#a)" />
                <path
                    d="M5.7,19.873l2.511-3.884-2.3-3.862H7.758L9.013,14.6c.116.234.2.408.238.524h.017c.082-.188.169-.369.26-.546l1.342-2.447h1.7l-2.359,3.84,2.419,3.905H10.821l-1.45-2.711A2.355,2.355,0,0,1,9.2,16.8H9.176a1.688,1.688,0,0,1-.168.351L7.515,19.873Z"
                    style="fill:#fff" />
                <path d="M28.806,3H19.581V9.5H30V4.191A1.192,1.192,0,0,0,28.806,3Z" style="fill:#33c481" />
                <path d="M19.581,16H30v6.5H19.581Z" style="fill:#107c41" />
            </svg>
            <span class="font-semibold text-sm text-gray-700">{{ attachment?.name }}</span>
        </div>
        <div class="flex space-x-4 mt-4 sm:mt-0">
            <a (click)="attachment?.url ? fileService.downloadFile(attachment?.url ?? '') : null"
                class="px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm text-sm text-gray-700 hover:bg-gray-100 cursor-pointer">
                {{ 'ATTACHMENT.DOWNLOAD' | transloco }}
            </a>
            <button *ngIf="hasRemoveButton" (click)="removeAttachement()"
                class="px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm text-sm text-red-500 hover:bg-gray-100">
                {{ 'ATTACHMENT.REMOVE' | transloco }}
            </button>
        </div>
    </div>
    <!-- <p class="text-sm text-gray-500 px-4 py-2 text-center">
        You can download the attached example and use them as a starting point for your own file.
    </p> -->
</div>
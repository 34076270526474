import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UiMode } from 'src/app/core/enums/ui-mode.enum';
import { Attachment } from 'src/app/core/models/attachment.model';
import { FileService } from 'src/app/core/services/file.service';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
})
export class UploadImageComponent implements OnInit {

  @Output() attachmentOutput = new EventEmitter<Attachment>();
  @Input() attachmentInput?: Attachment;
  @Input() canEdit: boolean = true;
  @Input() attachmentGroup: string = 'PROFILE_PICTURE';

  picPath: string | ArrayBuffer | null = null;
  
  image: any;
  
  constructor(private fileService: FileService) {}

  ngOnInit(): void {
   
  }

  async constructFileAndAffectItToUserPic(file: File) {
    if (file) {
      let attachment: Attachment = new Attachment();
      attachment.type = file.type;
      attachment.name = file.name;
      attachment.attachmentGroup = this.attachmentGroup;
      attachment.fileData = Array.from(new Uint8Array(await file.arrayBuffer()));      
      this.attachmentOutput.emit(attachment);
    }
  }

  onPicSelected(event: Event) {
    if (!this.canEdit) return;
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      this.constructFileAndAffectItToUserPic(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        this.picPath = e.target?.result as string;
      };
      reader.readAsDataURL(file);
    }
  }

  getPicture(): void {
    if (!this.attachmentInput?.url) return;

    this.fileService
      .getFile(this.attachmentInput?.url ?? '')
      .then((data) => {
        this.image = data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserListDto } from 'src/app/core/dtos/user-list.dto';
import { FileService } from 'src/app/core/services/file.service';

@Component({
  selector: 'app-profile-second-card',
  templateUrl: './profile-second-card.component.html',
  styleUrls: ['./profile-second-card.component.scss']
})
export class ProfileSecondCardComponent {

  @Input() profile?: UserListDto;
  profilePic: any;

  constructor(private fileService: FileService, private router: Router) {
  }

  ngOnInit(): void {
    this.getProfilePicture();
  }

  editProfile() {
    this.router.navigateByUrl('/profile/detail');
  }

  /**
   * Get the picture of the user that have made the request
   */
  getProfilePicture(): void {
    if (!this.profile?.attachment?.url) return;

    this.fileService.getFile(this.profile?.attachment?.url ?? '')
      .then(data => {
        this.profilePic = data;
      }).catch(error => {
        console.log(error);
      });
  }

}

import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertService } from "./alert.service";

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private headers: any = {};

  constructor(private _http: HttpClient, private _router: Router, private alertService: AlertService) { }

  getLang() {
    return localStorage.getItem("currentLang");
  }

  private setHeaders(captcha?: string, responseType?: any) {
    this.headers = {
      'Accept': '*/*',
      'Content-Type': 'application/json'
    };
    if (captcha) this.headers['Captcha-Token'] = captcha;
    if (responseType) this.headers['responseType'] = responseType;
    // Add Authorization header if token is available
    const token = localStorage.getItem('accessToken');
    if (token) {
      this.headers['Authorization'] = `Bearer ${token}`;
    }
  }

  post(route: string, data: any, captcha: string = "", responseType: any = 'json'): Promise<any> {
    this.setHeaders(captcha, responseType);
    return this._http.post(environment.apiUrl + route, data, { headers: new HttpHeaders(this.headers), responseType })
      .pipe(
        catchError(error => this.handleHttpError(error))
      )
      .toPromise();
  }

  get(route: string, params: any, captcha: string = ""): Promise<any> {
    this.setHeaders(captcha);
    return this._http.get(environment.apiUrl + route, { params, headers: new HttpHeaders(this.headers) })
      .pipe(
        catchError(error => this.handleHttpError(error))
      )
      .toPromise();
  }

  delete(route: string): Promise<any> {
    this.setHeaders();
    return this._http.delete(environment.apiUrl + route, { headers: new HttpHeaders(this.headers) })
      .pipe(
        catchError(error => this.handleHttpError(error))
      )
      .toPromise();
  }

  put(route: string, data: any): Promise<any> {
    this.setHeaders();
    return this._http.put(environment.apiUrl + route, data, { headers: new HttpHeaders(this.headers) })
      .pipe(
        catchError(error => this.handleHttpError(error))
      )
      .toPromise();
  }

  getFile(route: string, params: any): Promise<any> {
    this.setHeaders();
    return this._http.get(environment.apiUrl + route, { params, responseType: 'arraybuffer', headers: new HttpHeaders(this.headers) })
      .pipe(
        catchError(error => this.handleHttpError(error))
      )
      .toPromise();
  }

  downloadFile(route: string, params: any): Promise<any> {
    this.setHeaders();
    return this._http.get(environment.apiUrl + route, { params, responseType: 'arraybuffer', headers: new HttpHeaders(this.headers) })
      .pipe(
        catchError(error => this.handleHttpError(error))
      )
      .toPromise();
  }

  downloadFileFrom(route: string, params: any): Promise<Blob | undefined> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/octet-stream',
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    });

    return this._http.get(environment.apiUrl + route, {
      params,
      responseType: 'blob',
      headers: headers
    }).pipe(
      catchError(error => this.handleHttpError(error))
    ).toPromise();
  }

  handleHttpError(error: any): Observable<never> {
    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 0:
          this.alertService.showAlert('error', error.status.toString(), 'Le service est arrêté, veuillez contacter l\'équipe de développement');
          break;
        case 401:
          this.alertService.showAlert('error', error.status.toString(), error.error);
          this.logout();
          break;
        case 404:
          this.alertService.showAlert('error', error.status.toString(), error.error);
          break;
        case 400:
        case 500:
          this.alertService.showAlert('error', error.status.toString(), error.error);
          break;
        default:
          this.alertService.showAlert('error', error.status.toString(), error.error);
          break;
      }
    } else {
      this.alertService.showAlert('error', 'Unexpected Error', 'An unexpected error occurred.');
    }

    return throwError(() => error);
  }


  async logout() {
    localStorage.removeItem('tokenData');
    sessionStorage.removeItem('tokenData');
    await this._router.navigate(['auth/sign-in']);
  }
}

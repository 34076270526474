import { Component, Input } from '@angular/core';
import { PaginationService } from 'src/app/core/services/pagination.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() next: Function = () => {};  
  @Input() previous: Function = () => {};

  constructor(public paginationService: PaginationService) {
  }
  

}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RequestDto } from '../dtos/request.dto';
import { RequestPage } from '../display-models/request-page.model';
import { HttpService } from './http.service';
import { PaginationService } from './pagination.service';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  //date: string = new Date().toISOString();
  date: string | undefined; // = '2023-03-05T20:54:47';
  userName: string = '';
  userId: number | undefined;

  public requestPageChanged: BehaviorSubject<RequestPage> = new BehaviorSubject(this.setRequestPageChanged);
  public requestSubject: BehaviorSubject<RequestDto | undefined> = new BehaviorSubject(this.setRequestSubject);

  categoryMap: { [key: string]: string } = {
    'WORK_CERTIFICATE': 'Attestation de travail',
    'WORK_CERTIFICATE_2': 'Certificat de travail',
    'SALARY_CERTIFICATE': 'Attestation de salaire',
    'SALARY_ADVANCE': 'Avance sur salaire',
    'PAYSLIP': 'Bulletin de paie',
    'HOLIDAY': 'Congé',
    'LOAN_REQUEST': 'Demande de prêt',
  };

  priorityMap: { [key: string]: string } = {
    'LOW': 'Bas',
    'MEDIUM': 'Moyenne',
    'HIGH': 'Haut'
  };

  statusMap: { [key: string]: string } = {
    'PENDING': 'En attente',
    'IN_PROGRESS': 'En cours',
    'DONE': 'Effectué',
    'ACCEPTED': 'Accepté',
    'REFUSED': 'Rejeté',
    'CANCELED': 'Annulé'
  };


  constructor(public httpService: HttpService, public paginationService: PaginationService) {
  }

  public set setRequestPageChanged(value: RequestPage) {
    this.requestPageChanged.next(value);
  }

  public set setRequestSubject(value: RequestDto | undefined) {
    this.requestSubject.next(value);
  }

  public getCategoryKeys(): string[] {
    return Object.keys(this.categoryMap);
  }

  public getPriorityKeys(): string[] {
    return Object.keys(this.priorityMap);
  }

  public getStatusKeys(): string[] {
    return Object.keys(this.statusMap);
  }

  public resetFilters() {
    this.userName = '';
    this.date = undefined;
    this.paginationService.resetPageAndSize({});
  }

  public async retrieveRequestPage(status?: String) {
    let params: any = { page: this.paginationService.currentPage, size: this.paginationService.size };
    if (this.date) params = { date: new Date(this.date).toISOString(), ...params };
    if (this.userName) params = { userName: this.userName, ...params };
    //if (this.userId) params = { userId: this.userId, ...params };
    if (status) params = { status: status, ...params };
    this.httpService.get('/v1.0/request/findRequestByResponsibleHierarchyAndFilters', params).then((data: any) => {
      if (data) this.setRequestPageChanged = data;
      else this.setRequestPageChanged = new RequestPage();
    }).catch(error => {
      console.log(error);
    });
  }

  public async saveRequest(requestDto: RequestDto): Promise<any> {
    return this.httpService.post('/v1.0/request/save', requestDto);
  }

  public async updateRequest(requestDto: RequestDto): Promise<any> {
    return this.httpService.put('/v1.0/request/update', requestDto);
  }

  public async manageRequest(requestDto: RequestDto): Promise<any> {
    return this.httpService.put('/v1.0/request/manage', requestDto);
  }

  public async retrieveRequestPageByStatus() {
    let params: any = { page: this.paginationService.currentPage, size: this.paginationService.size };
    params = { status: 'PENDING', ...params };
    this.httpService.get('/v1.0/request/findByStatus', params).then((data: any) => {
      if (data) this.setRequestPageChanged = data;
      else this.setRequestPageChanged = new RequestPage();
    });
  }

  public async retrieveRequestById(id: number) {
    return this.httpService.get(`/v1.0/request/findById/${id}`, {});
  }


}

import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserDetailDto } from 'src/app/core/dtos/user-detail.dto';
import { Attachment } from 'src/app/core/models/attachment.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { FileService } from 'src/app/core/services/file.service';

@Component({
  selector: 'app-picture',
  templateUrl: './picture.component.html',
  styleUrls: ['./picture.component.scss']
})
export class PictureComponent {

  isFunctionLocked: boolean = false;

  @Input() isUrl: any = false;
  @Input() profilePic: any;
  @Input() downloadPicture: boolean = false;
  @Input() attachment?: Attachment;
  @Input() classStyle?: string;
  @Input() borderClassStyle?: string = 'p-1';

  @Input() name?: String;
  @Input() name2?: String;

  constructor(private fileService: FileService, private authService: AuthService) { }

  ngOnInit(): void {
    if (this.downloadPicture === true) {
      this.getProfilePicture();
    } else if (this.downloadPicture === false && this.profilePic === undefined) {
      this.authService.getProfilePicture(this.attachment!);
    }
  }

  /**
   * On profile pic clicked open dialog to choose an image then convert it to our own file object
   * @param event 
   * @returns 
   */
  async onProfilePicSelected(event: any) {
    if (this.isFunctionLocked) return;
    this.isFunctionLocked = true;
    const fileInput: any = document.getElementById('file-profile-pic-input');
    fileInput.click();
    fileInput.addEventListener('change', (event: any) => {

      if (event.target.files[0]) {
        this.displaySelectedImage(event.target.files[0]);
        this.constructFileAndAffectItToUserPic(event.target.files[0]);
        this.isFunctionLocked = false;
      } else {
        this.isFunctionLocked = false;
      }
    });
  }

  displaySelectedImage(file: File) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.profilePic = e.target.result;
    };
    reader.readAsDataURL(file);
  }

  async constructFileAndAffectItToUserPic(file: File) {
    if (file) {
      let attachment: Attachment = new Attachment();
      attachment.type = file.type;
      attachment.name = file.name;
      attachment.attachmentGroup = 'PROFILE_PICTURE';
      attachment.fileData = Array.from(new Uint8Array(await file.arrayBuffer()));

      // this.user.personalInformation!.attachment = attachment;
    }
  }

  // Download user profile picture
  getProfilePicture() {
    if (!this.attachment?.url || this.profilePic) return;
    this.fileService.getFile(this.attachment?.url ?? '')
      .then(data => {
        // this.imageSrc = this.fileService.getImageToDisplay(data, this.attachment?.type ?? 'png');
        this.profilePic = data;
      }).catch(error => {
        console.log(error);
      });
  }


}

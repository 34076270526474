<div class="h-full antialiased sans-serif">
    <!-- <label for="datepicker" class="font-bold mb-1 text-gray-700 block">Select Date</label> -->
    <div #datepickerInput class="relative">
        <input type="hidden" name="date" x-ref="date" />
        <div (click)="toggleShowDatePicker()">
            <input type="text" readonly [(ngModel)]="datepickerValue"
                (keydown.escape)="showDatepicker = false"
                class="w-full pl-4 pr-10 py-3 leading-none rounded-lg shadow-sm focus:outline-none focus:shadow-outline text-gray-600 font-medium"
                placeholder="{{ 'DATE_PICKER.SELECT_A_DATE' | transloco }}" />
            <div class="absolute top-3 right-3">
                <svg class="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
            </div>
        </div>

        <!-- <div {{no_of_days.length"></div>
              <div {{32 - new Date(year, month, 32).getDate()"></div>
              <div {{new Date(year, month).getDay()"></div> -->
        <div class="bg-white mt-12 rounded-lg shadow p-4 absolute top-0  z-10"
            style="width: 17rem;     right: -2px;" [hidden]="!showDatepicker"
            (keydown.away)="showDatepicker = false">
            <div class="flex justify-between items-center mb-2">
                <div>
                    <span class="text-lg font-bold text-gray-800">{{ MONTH_NAMES[month] }}</span>
                    <span class="ml-1 text-lg text-gray-600 font-normal">{{ year }}</span>
                </div>
                <div>
                    <button type="button"
                        class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
                        [ngClass]="{ 'cursor-not-allowed opacity-25': month === 0 }"
                        [disabled]="month === 0 ? true : false" (click)="month = month - 1"
                        (click)="getNoOfDays()">
                        <svg class="h-6 w-6 text-gray-500 inline-flex" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M15 19l-7-7 7-7" />
                        </svg>
                    </button>
                    <button type="button"
                        class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
                        [ngClass]="{ 'cursor-not-allowed opacity-25': month === 11 }"
                        [disabled]="month === 11 ? true : false" (click)="month = month + 1"
                        (click)="getNoOfDays()">
                        <svg class="h-6 w-6 text-gray-500 inline-flex" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M9 5l7 7-7 7" />
                        </svg>
                    </button>
                </div>
            </div>

            <div class="flex flex-wrap mb-3 -mx-1">
                <ng-container *ngFor="let day of DAYS; let index = index">
                    <div style="width: 14.26%" class="px-1">
                        <div class="text-gray-800 font-medium text-center text-xs">{{ day }}</div>
                    </div>
                </ng-container>
            </div>

            <div class="flex flex-wrap -mx-1">
                <ng-container *ngFor="let blankday of blankdays">
                    <div style="width: 14.28%"
                        class="text-center border-none p-1 border-transparent text-sm"></div>
                </ng-container>
                <ng-container
                    *ngFor="let date of no_of_days; let dateIndex = index; trackBy: trackByIdentity">
                    <div style="width: 14.28%" class="px-1 mb-1">
                        <div (click)="getDateValue(date)"
                            class="cursor-pointer text-center text-sm rounded-full leading-loose transition ease-in-out duration-100"
                            [ngClass]="{ 'bg-red-400 text-white': isToday(date) === true, 'text-gray-700 hover:bg-blue-200': isToday(date) === false, 'bg-green-300 text-white': isSelectedDate(date) === true }">
                            {{ date }}
                        </div>
                    </div>
                </ng-container>
            </div>
            <span class="text-sm text-gray-500 cursor-pointer" (click)="clearCalendar()">{{ 'DATE_PICKER.CLEAR' | transloco }}</span>
        </div>
    </div>
</div>
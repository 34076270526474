import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit {


  @Input() kpi: number = 0;
  style: string = "inline-block items-center px-2 py-1 rounded-md text-xs font-medium text-center text-grey-900"
  text: string = "";

  ngOnInit(): void {
    if (this.kpi) {
      if (this.kpi === 0) {
        this.style += " bg-grey-100"
        this.text = "0 h";
      } else if (this.kpi > 0) {
        this.style += " bg-green-100"
        this.text = "+" + this.kpi.toFixed(2) + " j";
      } else {
        this.style += " bg-red-100"
        this.text = this.kpi.toFixed(2) + " j";
      }
    }


  }



}
